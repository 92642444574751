// @ts-nocheck
import React from "react"
import { Link } from "react-router-dom"
import TaskSkeleton from 'screens/skeletons/Task.skeleton'
import GeneralError from 'screens/errors/GeneralError'
import TaskController from 'controllers/Task.controller'

import Stripe from 'components/Payment/Stripe'
import Paypal from 'components/Payment/Paypal'
import TaskBid from 'components/Task/TaskBid'
import TaskBids from 'components/Task/TaskBids'
import TaskTranslatorFiles from 'components/Task/TaskTranslatorFiles'
import TaskPriceEstimationLoading from 'components/Task/TaskPriceEstimationLoading'

import { apiToUiDate } from 'utils/datetime'
import { humanFileSize } from 'utils/files'

const Task = ({ 
    id,
    view,
 }) => {

    const {
        isLoading,
        error,
        languages,
        taskTypes,
        task,
        setTask,
        statuses,
        isMyTask,
        isTaskAssignedToMe,
        paymentStep,
        setPaymentStep,
        refetchTask,
        activity,
        currentUser,
        setFileStatus,
        isUpdatingStatus,
        setIsUpdatingStatus,
        updateTaskStatus,
        refetchActivity,
        hasEstimationError,
        isEstimationLoading,
    } = TaskController({ id });

    return (
        <>
            { isLoading && <TaskSkeleton /> }
            { error && (
                <GeneralError
                    title="Auftrag"
                >{ error }</GeneralError>
            ) }
            { task && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title">Auftrag Details</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {/* project card */}
                            <div className="card d-block">
                                <div className="card-body">
                                    <h2 className="mt-0 mb-3 text-start">{ task.title }</h2>
                                    <div className="row">
                                        <div className="col-6">
                                            { isMyTask && task.status === "in_progress" ? (
                                                <>
                                                    { isUpdatingStatus ? (
                                                        <div className="input-group">
                                                            <select
                                                                value="in_progress"
                                                                className="form-select"
                                                                onChange={(e) => {
                                                                    updateTaskStatus(e.target.value)
                                                                }}
                                                            >
                                                                <option value="in_progress">In Bearbeitung</option>
                                                                <option value="completed">Erledigt</option>
                                                                <option value="cancelled">Storniert</option>
                                                            </select>
                                                            <span className="input-group-text mdi mdi-close" onClick={() => setIsUpdatingStatus(false)} />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="badge bg-secondary text-light mb-3"
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => setIsUpdatingStatus(true)}
                                                        >In Bearbeitung</div>
                                                    ) }
                                                </>
                                            ) : (
                                                <>
                                                    { task.status === 'draft' && (
                                                        <div className="badge bg-secondary text-light mb-3">Eingegangen</div>
                                                    ) }
                                                    { task.status === 'open' && (
                                                        <div className="badge badge badge-success-lighten mb-3">Offen</div>
                                                    ) }
                                                    { task.status === 'in_progress' && (
                                                        <div className="badge bg-secondary text-light mb-3">In Bearbeitung</div>
                                                    ) }
                                                    { task.status === 'completed' && (
                                                        <div className="badge bg-primary mb-3">Erledigt</div>
                                                    ) }
                                                    { task.status === 'cancelled' && (
                                                        <div className="badge badge-danger-lighten mb-3">Storniert</div>
                                                    ) }
                                                </>
                                            ) }

                                            { task.status !== 'draft' && !task.payment_is_paid && (
                                                <div className="ms-2 badge badge-warning-lighten mb-3">
                                                    {task.payment_is_paid_status === "NOT_PAID" && "Nicht bezahlt"}
                                                    {task.payment_is_paid_status === "PARTIAL_PAID" && "Teilzahlung"}
                                                    {task.payment_is_paid_status === "CANCELLED" && "Storno"}
                                                </div>
                                            ) }
                                            { task.status !== 'draft' && task.payment_is_paid && (
                                                <div className="ms-2 badge badge-outline-success mb-3">bezahlt</div>
                                            ) }
                                            <h5>Auftragbeschreibung:</h5>
                                            <p className="text-muted mb-4">
                                                { task.description }
                                            </p>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <h5>Erstellt</h5>
                                                        <p>
                                                            { apiToUiDate(task.entry_date) }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h5>Auftraggeber:</h5>
                                                    <Link to={ view === "customer" ? `/customer/customers/${task.customer.id}` : `/translator/customers/${task.customer.id}` }>
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                                <div className="avatar-sm">
                                                                    <img
                                                                        src="/assets/images/users/avatar-0.png"
                                                                        className="rounded-circle avatar-sm img-thumbnail"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col text-secondary">
                                                                <div>
                                                                    <h4 className="mb-0">{ task.customer.name }</h4>
                                                                    <div className="font-13 text-muted mb-2">{ task.customer.role }</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* BIDS */}

                                        { task.status === "open" && (
                                            <div className="col-6">
                                                { view === "customer" && (
                                                    <>
                                                        <TaskBids task={task} />
                                                    </>
                                                ) }
                                                { view === "translator" && (
                                                    <>
                                                        {task.bids.find(b => b.translator.id === currentUser.id) ? (
                                                            <>
                                                                Sie haben sich für diesen Auftrag beworben
                                                            </>
                                                        ) : (
                                                            <TaskBid
                                                                task={task}
                                                                setTask={setTask}
                                                                refetchActivity={refetchActivity}
                                                            />
                                                        )}
                                                    </>
                                                ) }
                                            </div>
                                        ) }
                                    </div>
                                </div>{" "}
                                {/* end card-body*/}
                            </div>

                            {/* DOCUMENTS AND INTERPRETATIONS */}

                            { (task.status !== "open" || view !== "translator") && (
                                <>
                                    { task.task_type === "translation" && (
                                        <>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title mb-3">Dokumente</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-condensed">
                                                            <thead>
                                                                <tr>
                                                                    <th>Datei</th>
                                                                    <th>Typ</th>
                                                                    <th>Sprachen</th>
                                                                    <th>Auftragnehmer</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { task.files.map((file, i) => (
                                                                    <tr key={ i }>
                                                                        <td>
                                                                            <div className="row align-items-center" style={ { width: '250px' } }>
                                                                                <div className="col-auto">
                                                                                    <div className="avatar-sm">
                                                                                        <span className="avatar-title rounded">
                                                                                            <a 
                                                                                                href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                                target="_blank"
                                                                                                className="text-white"
                                                                                            >
                                                                                                { file.extension }
                                                                                            </a>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col ps-0 text-truncate">
                                                                                    <div className="text-muted fw-bold">
                                                                                        <a 
                                                                                            href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                            target="_blank"
                                                                                            className="text-muted text-ellipsis text-truncate d-inline-block w-100"
                                                                                        >
                                                                                            { file.filename }
                                                                                        </a>
                                                                                    </div>
                                                                                    <p className="mb-0">{ humanFileSize(file.size) }</p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            { taskTypes.find(t => t.key === file.doc_type)?.value }
                                                                        </td>
                                                                        <td>
                                                                            <span className="pe-2">{ languages.find(l => l.key === file.source_language)?.value }</span>
                                                                            <i className="uil-arrow-right" />
                                                                            <span className="ps-2">{ languages.find(l => l.key === file.target_language)?.value }</span>
                                                                        </td>
                                                                        <td>
                                                                            { file.translator ? (
                                                                                <div>
                                                                                    { view === "customer" ? (
                                                                                        <Link className="text-info" to={ `/customer/translators/${file.translator.id}` }>
                                                                                            {file.translator.name} {file.translator.email && `(${file.translator.email})`}
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <span>
                                                                                            {file.translator.name}
                                                                                        </span>
                                                                                    ) }
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    Noch nicht zugewiesen
                                                                                </div>
                                                                            ) }
                                                                        </td>
                                                                        <td>
                                                                            <div className="btn-group">
                                                                                <button 
                                                                                    type="button"
                                                                                    className={ `btn btn-sm btn-secondary ${file.status !== 0 ? 'opacity-25' : ''}` }
                                                                                    title="Eingegangen"
                                                                                    onClick={() => setFileStatus(file, 0)}
                                                                                >
                                                                                    <span className="opacity-0">0</span>
                                                                                </button>
                                                                                <button 
                                                                                    type="button"
                                                                                    className={ `btn btn-sm btn-danger ${file.status !== 1 ? 'opacity-25' : ''}` }
                                                                                    title="Storniert"
                                                                                    onClick={() => setFileStatus(file, 1)}
                                                                                >
                                                                                    <span className="opacity-0">1</span>
                                                                                </button>
                                                                                <button 
                                                                                    type="button"
                                                                                    className={ `btn btn-sm btn-warning ${file.status !== 2 ? 'opacity-25' : ''}` }
                                                                                    title="In Bearbeitung"
                                                                                    onClick={() => setFileStatus(file, 2)}
                                                                                >
                                                                                    <span className="opacity-0">2</span>
                                                                                </button>
                                                                                <button 
                                                                                    type="button"
                                                                                    className={ `btn btn-sm btn-success ${file.status !== 3 ? 'opacity-25' : ''}` }
                                                                                    title="Erledigt"
                                                                                    onClick={() => setFileStatus(file, 3)}
                                                                                >
                                                                                    <span className="opacity-0">3</span>
                                                                                </button>
                                                                            </div>
                                                                            <br />
                                                                            { file.status === 0 && "Eingegangen" }
                                                                            { file.status === 1 && "Storniert" }
                                                                            { file.status === 2 && "In Bearbeitung" }
                                                                            { file.status === 3 && "Erledigt" }
                                                                        </td>
                                                                    </tr>
                                                                )) }
                                                            </tbody>  
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) }

                                    {["interpretation", "lesson"].includes(task.task_type) && (
                                        <>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title mb-3">
                                                        { task.task_type === "lesson" && "Sprachunterricht" }
                                                        { task.task_type === "interpretation" && "Dolmetschereinsatz" }
                                                    </h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-condensed table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sprachen</th>
                                                                    <th>Auftragnehmer</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { task.interpretations.map((interpretation, i) => (
                                                                    <React.Fragment key={ i }>
                                                                        <tr>
                                                                            <td>
                                                                                <span className="pe-2">{ languages.find(l => l.key === interpretation.source_language)?.value }</span>
                                                                                <i className="uil-arrow-right" />
                                                                                <span className="ps-2">{ languages.find(l => l.key === interpretation.target_language)?.value }</span>
                                                                            </td>
                                                                            <td>
                                                                                { interpretation.translator ? (
                                                                                    <div>
                                                                                        { view === "customer" ? (
                                                                                            <Link className="text-info" to={ `/customer/translators/${interpretation.translator.id}` }>
                                                                                                {interpretation.translator.name} {interpretation.translator.email && `(${interpretation.translator.email})`}
                                                                                            </Link>
                                                                                        ) : (
                                                                                            <span>
                                                                                                {interpretation.translator.name}
                                                                                            </span>
                                                                                        ) }
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        Noch nicht zugewiesen
                                                                                    </div>
                                                                                ) }
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )) }
                                                            </tbody>
                                                            
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {/* TRANSLATOR DOCUMENTS */}
                                    { task.task_type === "translation" && (task.translator_files.length > 0 || !["draft", "open"].includes(task.status)) && (
                                        <TaskTranslatorFiles
                                            task={task}
                                            setTask={setTask}
                                            isEditable={isTaskAssignedToMe && task.status === "in_progress"}
                                            refetchActivity={refetchActivity}
                                        />
                                    ) }

                                    {/* PAYMENT INFO */}

                                    { (isMyTask || isTaskAssignedToMe) && (
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-3">Zahlungsinformationen</h5>
                                                { hasEstimationError && (
                                                    <div className="alert alert-danger">
                                                        Bei der Schätzung des Preises für diesen Auftrag ist ein Fehler aufgetreten.
                                                    </div>
                                                ) }
                                                { isEstimationLoading && (
                                                    <TaskPriceEstimationLoading />
                                                ) }
                                                { task.status === "draft" && task.is_price_estimated && (
                                                    <>
                                                        <div className="alert alert-info">
                                                            Die folgenden Preise wurden von einer Maschine geschätzt. Sie können sich noch ändern, wenn der Auftrag genehmigt wird
                                                        </div>
                                                        { task.files_not_estimated.length > 0 && (
                                                            <div className="alert alert-warning">
                                                                Die folgenden Dokumente konnten nicht verarbeitet werden:
                                                                <ul>
                                                                    { task.files_not_estimated.map((file_not_estimated, i) => (
                                                                        <li key={i}>{ file_not_estimated }</li>
                                                                    )) }
                                                                </ul>
                                                            </div>
                                                        ) }
                                                    </>
                                                ) }
                                                { task.task_type === "translation" && (task.status !== "draft" || task.is_price_estimated) && (
                                                    <div className="table-responsive">
                                                        <table className="table table-condensed table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Dokument</th>
                                                                    <th>Rechnung</th>
                                                                    <th>Netto Preis / St.</th>
                                                                    <th>Menge</th>
                                                                    <th>Netto Preis</th>
                                                                    <th colSpan={2}>Umsatzsteuer</th>
                                                                    <th>Brutto Preis</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { task.files.map((file, i) => (
                                                                    <tr key={ i }>
                                                                        <td>
                                                                            <div className="text-muted fw-bold text-ellipsis text-truncate" style={ { width: '200px' } }>
                                                                                <a 
                                                                                    href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                    target="_blank"
                                                                                    className="text-muted text-ellipsis text-truncate d-inline-block w-100"
                                                                                >
                                                                                    { file.filename }
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="text-muted text-ellipsis text-truncate" style={ { width: '185px' } }>
                                                                                { file.invoice_file ? (
                                                                                    <div className="clear text-truncate text-ellipsis">
                                                                                        <a 
                                                                                            href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.invoice_real_file}` } 
                                                                                            target="_blank"
                                                                                            className="text-dark"
                                                                                        >
                                                                                            { file.invoice_file }
                                                                                        </a>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        Rechnung noch nicht vorhanden
                                                                                    </div>
                                                                                ) }
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {file.unit_price} €
                                                                        </td>
                                                                        <td>
                                                                            { file.qty }
                                                                        </td>
                                                                        <td>
                                                                            { file.price } €
                                                                        </td>
                                                                        <td>
                                                                            { file.tax_percentage } %
                                                                        </td>
                                                                        <td>
                                                                            { file.tax_amount } €
                                                                        </td>
                                                                        <td>
                                                                            { file.total_price } €
                                                                        </td>
                                                                    </tr>
                                                                )) }
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        <strong>Gesamt</strong>
                                                                    </td>
                                                                    <td>
                                                                        { task.price } €
                                                                    </td>
                                                                    <td></td>
                                                                    <td>
                                                                        { task.tax_amount } €
                                                                    </td>
                                                                    <td>
                                                                        { task.total_price } €
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            
                                                        </table>
                                                    </div>
                                                ) }
                                                { ["interpretation", "lesson"].includes(task.task_type) && task.status !== "draft" && (
                                                    <table className="table table-condensed table-bordered ">
                                                        <thead>
                                                            <tr>
                                                                <th>Position</th>
                                                                <th colSpan={2}>Menge</th>
                                                                <th>EinzelPreis <span className="text-muted">(Netto)</span></th>
                                                                <th>USt.</th>
                                                                <th>Betrag <span className="text-muted">(Brutto)</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { task.interpretation_billings.map((interpretation_billing, i) => (
                                                                <React.Fragment key={ i }>
                                                                    <tr>
                                                                        <td>
                                                                            {interpretation_billing.title}
                                                                        </td>
                                                                        <td>
                                                                            { interpretation_billing.amount } €
                                                                        </td>
                                                                        <td>
                                                                            { interpretation_billing.amount_type }
                                                                        </td>
                                                                        <td>
                                                                            { interpretation_billing.unit_price }
                                                                        </td>
                                                                        <td>
                                                                            { interpretation_billing.tax_percentage } %
                                                                        </td>
                                                                        <td>
                                                                            { interpretation_billing.total_price } €
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={6}>
                                                                            {interpretation_billing.notes}
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )) }
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <strong>Gesamt</strong>
                                                                </td>
                                                                <td>
                                                                    { task.total_price } €
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={3}>
                                                                    <strong>Zu zahlen</strong>
                                                                </td>
                                                                <td>
                                                                    { task.payment_price } €
                                                                </td>
                                                                <td>
                                                                    { task.payment_tax_amount } €
                                                                </td>
                                                                <td>
                                                                    { task.payment_total_price } €
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        
                                                    </table>
                                                ) }

                                                { task.paypal_invoice_id && (
                                                    <div className="mb-4">
                                                        <h5 className="card-title mb-3">Rechnung</h5>
                                                        <a className="btn btn-dark" href={`https://www.sandbox.paypal.com/invoice/s/pay/${task.paypal_invoice_id}`} target="_blank">
                                                            <i className="uil-euro"></i> Rechnung Anzeigen
                                                        </a>
                                                    </div>
                                                ) }
                                                <h5 className="card-title mb-3">Status</h5>
                                                { task.status !== 'draft' && !task.payment_is_paid && (
                                                    <div className="badge badge-warning-lighten mb-3">
                                                        {task.payment_is_paid_status === "NOT_PAID" && "Nicht bezahlt"}
                                                        {task.payment_is_paid_status === "PARTIAL_PAID" && "Teilzahlung"}
                                                        {task.payment_is_paid_status === "CANCELLED" && "Storno"}
                                                    </div>
                                                ) }
                                                { task.status !== 'draft' && task.payment_is_paid && (
                                                    <div className="badge badge-outline-success mb-3">bezahlt</div>
                                                ) }
                                                { task.status === 'draft' && (
                                                    <p>Der Auftrag wurde noch nicht genehmigt. Erst wenn der Auftrag genehmigt ist, kann er bezahlt werden</p>
                                                ) }
                                                { task.status !== 'draft' && task.payment_is_paid && (
                                                    <>
                                                        <h5 className="card-title mb-3">Zahlung</h5>
                                                        <p>
                                                            Methode: {" "}
                                                            { task.payment_method === 'PAYPAL' && 'PayPal' }
                                                            { task.payment_method === 'STRIPE' && 'Stripe' }
                                                            { task.payment_method === 'CASH' && 'Bar' }
                                                            { task.payment_method === 'TRANSFER' && 'Überweisung' }
                                                        </p>
                                                        <p>Bezahlter Betrag: { task.payment_paid_amount } €</p>
                                                    </>
                                                ) }
                                                { task.status !== 'draft' && !task.payment_is_paid && isMyTask && (
                                                    <>
                                                        <p>Betrag zu zahlen: { task.payment_total_price } €</p>
                                                        { !paymentStep && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    setPaymentStep('CHOICE')
                                                                }}
                                                            >Jetzt bezahlen</button>
                                                        ) }
                                                        { paymentStep === 'CHOICE' && (
                                                            <div style={{maxWidth: 750}}>
                                                                <div>
                                                                    <Paypal 
                                                                        task={task}
                                                                        onSuccess={() => {
                                                                            refetchTask()
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="mb-2">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-lg btn-block btn-info me-2"
                                                                        onClick={() => {
                                                                            setPaymentStep('STRIPE')
                                                                        }}
                                                                        style={{width: "100%"}}
                                                                    >
                                                                        Bankkarte / Kreditkarte
                                                                        <span className="ms-2 text-light" style={{fontSize: 14}}>(Powered by Stripe)</span>
                                                                    </button>
                                                                </div>
                                                                
                                                                <div class="text-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light"
                                                                        onClick={() => {
                                                                            setPaymentStep('')
                                                                        }}
                                                                    >Abbrechen</button>
                                                                </div>
                                                            </div>
                                                        ) }
                                                        { paymentStep === 'STRIPE' && (
                                                            <div>
                                                                <Stripe 
                                                                    task={task}
                                                                    onCancel={() => {
                                                                        setPaymentStep('CHOICE')
                                                                    }}
                                                                    onSuccess={() => {
                                                                        refetchTask()
                                                                    }}
                                                                />
                                                            </div>
                                                        ) }
                                                    </>
                                                ) }
                                            </div>
                                        </div>
                                    ) }

                                    { activity && (
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-3">Verlauf</h5>
                                                <div className="timeline-alt pb-0">
                                                    { activity.filter(a => a.is_public).map((activity, i) => (
                                                        <div className="timeline-item">
                                                            <i className={ `mdi mdi-circle ${i % 2 === 0 ? "bg-info-lighten" : "bg-primary-lighten"} text-info timeline-icon`} />
                                                            <div className="timeline-item-info">
                                                                <h5 className="mt-0 mb-1">
                                                                    { activity.is_status_change ? (
                                                                        <span>
                                                                            Der Status des Auftrags wurde von{' '}
                                                                            <span className="badge bg-secondary text-light">{ statuses.find(s => s.key === activity.previous_status)?.value }</span>
                                                                            {' '}zu{' '}
                                                                            <span className="badge bg-secondary text-light">{ statuses.find(s => s.key === activity.new_status)?.value }</span>
                                                                            {' '}geändert
                                                                        </span>
                                                                    ) : activity.title }    
                                                                </h5>
                                                                <p className="font-14">
                                                                    { activity.author.role === 'translator' ? (
                                                                        <Link to={ `/customer/translators/${activity.author.id}` } className="text-secondary">
                                                                            { activity.author.name }
                                                                        </Link>
                                                                    ) : activity.author.role === 'customer' ? (
                                                                        <Link to={ `/customer/customers/${activity.author.id}` } className="text-secondary">
                                                                            { activity.author.name }
                                                                        </Link>
                                                                    ) : <span>{ activity.author.name }</span> }
                                                                    {" "}<span className="ms-2 font-12">{ apiToUiDate(activity.activity_date) }</span>
                                                                </p>
                                                                <p className="text-muted mt-2 mb-0 pb-3">
                                                                    { activity.description }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )) }
                                                </div>
                                            </div>
                                        </div>
                                    ) }
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) }
        </>
    )
}

export default Task