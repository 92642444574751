// @ts-nocheck
import { Link } from "react-router-dom"

import TranslatorMyTasksController from 'screens/translator/TranslatorMyTasks/TranslatorMyTasks.controller'
import TranslatorMyTasksSkeleton from 'screens/translator/TranslatorMyTasks/TranslatorMyTasks.skeleton'

import { apiToUiDate } from 'utils/datetime'

const TranslatorMyTasks = (props) => {
    const {
        tasks,
        isLoading,
        error,
        languages,
        statuses,
        taskTypes,
        buildLink,
        q,
        status, 
        lang,
        updateSearchCriteria,
    } = TranslatorMyTasksController(props)

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <h4 className="page-title">Meine Aufträge</h4>
                    </div>
                </div>
            </div>     

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-xl-8">
                                    <form className="row gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between" onSubmit={(e) => e.preventDefault()}>
                                        <div className="col-auto">
                                            <input
                                                type="search"
                                                className="form-control"
                                                placeholder="Suchen..."
                                                value={ q }
                                                onChange={ (e) => { 
                                                    updateSearchCriteria({
                                                        q: e.target.value
                                                    })
                                                } }
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                <label for="status-select" className="me-2">Status</label>
                                                <select
                                                    className="form-select"
                                                    id="status-select"
                                                    value={ status }
                                                    onChange={ (e) => { 
                                                        updateSearchCriteria({
                                                            status: e.target.value
                                                        })
                                                    } }
                                                >
                                                    <option value="all">Alle</option>
                                                    { statuses.map(status => (
                                                        <option value={ status.key } key={ status.key }>{ status.value }</option>
                                                    )) }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                <label for="lang-select" className="me-2">Sprache</label>
                                                <select
                                                    className="form-select"
                                                    id="lang-select"
                                                    value={ lang }
                                                    onChange={ (e) => { 
                                                        updateSearchCriteria({
                                                            lang: e.target.value
                                                        })
                                                    } }
                                                >
                                                    <option value="all">Alle</option>
                                                    { languages.map(language => (
                                                        <option value={ language.key } key={ language.key }>{ language.value }</option>
                                                    )) }
                                                </select>
                                            </div>
                                        </div>
                                    </form>                            
                                </div>
                            </div>

                            { isLoading && <TranslatorMyTasksSkeleton /> }
                            { error && (
                                <div>{error}</div>
                            ) }
                            
                            { tasks && tasks.results.length === 0 && (
                                <div>Es wurden keine Aufträge gefunden</div>
                            ) }
    
                            { tasks && tasks.results.length > 0 && (
                                <>
                                    <div className="table-responsive">
                                        <table className="table table-centered mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Betreff</th>
                                                    <th>Dokument-Typ</th>
                                                    <th>Erstellt</th>
                                                    <th>Status</th>
                                                    <th>Bezahlt</th>
                                                    <th colSpan={ 1 }>Sprachen</th>
                                                    <th>Gesamtbetrag</th>
                                                    <th style={ { width: "125px" } }>Aktionen</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { tasks.results.map((task) => (
                                                    <tr key={ task.id }>
                                                        <td>
                                                            <div>
                                                                <Link to={ `/translator/tasks/${task._id}` } className="d-inline-block text-body fw-bold text-truncate" title={ task.title } style={ { maxWidth: "150px" } }>
                                                                    { task.title }
                                                                </Link>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            { task.task_type === "translation" && (
                                                                <>
                                                                    { [...new Set(task.files.map(f => f.doc_type).filter(l => l))].map(f => taskTypes.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            ) }
                                                            { task.task_type === "interpretation" && "Dolmetschen" }
                                                            { task.task_type === "lesson" && "Sprachunterricht" }
                                                        </td>
                                                        <td>
                                                            { apiToUiDate(task.entry_date) }
                                                        </td>
                                                        <td>
                                                            { task.status === 'draft' && (
                                                                <div className="badge bg-secondary">Eingegangen</div>
                                                            ) }
                                                            { task.status === 'open' && (
                                                                <div className="badge badge badge-success-lighten">Offen</div>
                                                            ) }
                                                            { task.status === 'in_progress' && (
                                                                <div className="badge bg-secondary text-light">In Bearbeitung</div>
                                                            ) }
                                                            { task.status === 'completed' && (
                                                                <div className="badge bg-primary">Erledigt</div>
                                                            ) }
                                                            { task.status === 'cancelled' && (
                                                                <div className="badge badge-danger-lighten">Storniert</div>
                                                            ) }
                                                        </td>
                                                        <td>    
                                                            { task.status !== 'draft' && !task.payment_is_paid && (
                                                                <div className="badge badge-warning-lighten">
                                                                    {task.payment_is_paid_status === "NOT_PAID" && "Nicht bezahlt"}
                                                                    {task.payment_is_paid_status === "PARTIAL_PAID" && "Teilzahlung"}
                                                                    {task.payment_is_paid_status === "CANCELLED" && "Storno"}
                                                                </div>
                                                            ) }
                                                            { task.status !== 'draft' && task.payment_is_paid && (
                                                                <div className="badge badge-outline-success">bezahlt</div>
                                                            ) }
                                                        </td>
                                                        <td>
                                                            { task.task_type === "translation" && (
                                                                <>
                                                                    { [...new Set(task.files.map(f => f.source_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                    {" "}<i className="uil-arrow-right" />{" "}
                                                                    { [...new Set(task.files.map(f => f.target_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            )}
                                                            { ["interpretation", "lesson"].includes(task.task_type) && (
                                                                <>
                                                                    { [...new Set(task.interpretations.map(f => f.source_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                    {" "}<i className="uil-arrow-right" />{" "}
                                                                    { [...new Set(task.interpretations.map(f => f.target_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            { task.total_price } €
                                                        </td>
                                                        <td>
                                                            <Link to={ `/translator/tasks/${task._id}` } className="action-icon">
                                                                <i className="mdi mdi-eye" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )) } 
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <nav>
                                            <ul className="pagination mb-0 mt-3">
                                                { tasks.page > 1 && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: tasks.page - 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">«</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                                { Array.from(Array(tasks.totalPages).keys()).map(i => i + 1).map((targetPage) => (
                                                    <li className={ `page-item ${targetPage !== tasks.page ? "" : "active text-dark"}` }>
                                                        <Link to={ buildLink({ page: targetPage }) } className="page-link">
                                                            { targetPage }
                                                        </Link>
                                                    </li>
                                                )) }
                                                { tasks.page < tasks.totalPages && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: tasks.page + 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">»</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                            </ul>
                                        </nav>
                                    </div>
                                </>
                            )}
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default TranslatorMyTasks