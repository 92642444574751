// @ts-nocheck
import ProfileController from 'controllers/components/Messenger/Profile.controller'

function formatLastSeen(timestamp) {
    if (!timestamp) {
        return "Nie";
    }

    const now = new Date();
    const lastSeen = new Date(timestamp);
    const diffInSeconds = Math.floor((now - lastSeen) / 1000);
    
    if (diffInSeconds < 60) {
        return "gerade eben";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `vor ${diffInMinutes} ${diffInMinutes === 1 ? "Minute" : "Minuten"}`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return `vor ${diffInHours} ${diffInHours === 1 ? "Stunde" : "Stunden"}`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) {
        return `vor ${diffInDays} ${diffInDays === 1 ? "Tag" : "Tagen"}`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
        return `vor ${diffInMonths} ${diffInMonths === 1 ? "Monat" : "Monaten"}`;
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    return `vor ${diffInYears} ${diffInYears === 1 ? "Jahr" : "Jahren"}`;
}

function isOnline(user) {
    if(!user.last_seen_online) {
        return false
    }

    const now = new Date();
    const lastSeen = new Date(user.last_seen_online);
    const diffInSeconds = Math.floor((now - lastSeen) / 1000);
    
    return diffInSeconds < 60
}

const Profile = (props) => {
    const { user } = props

    const {
        callUser,
        isLoading,
    } = ProfileController(props)

    return (
        <div className="card">
            <div className="card-body">
                <div className="mt-3 text-center">
                    <img src="/assets/images/users/avatar-0.png" alt="shreyu" className="img-thumbnail avatar-lg rounded-circle" />
                    <h4>{ user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` }</h4>
                    <button
                        className={ `btn btn-info btn-sm mt-1 ${isLoading ? 'disabled' : ''}` }
                        onClick={ callUser }
                        disabled={ isLoading || !isOnline(user) }
                    >Anrufen</button>
                    <p className="text-muted mt-2 font-14">Zuletzt online: <strong>{formatLastSeen(user.last_seen_online)}</strong></p>
                    { !isOnline(user) && (
                        <p><br />{ user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` } kann nicht derzeit angerufen werden</p>
                    ) }
                </div>
            </div>
        </div>
    )
}

export default Profile