// @ts-nocheck
import { useState, useEffect } from 'react'
import {
    useParams,
    useHistory
  } from "react-router-dom";
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"

import { formatDateForApi } from 'utils/datetime'
import {
    processAppSettingsKeyValueArray,
    processStaticSettingsObject,
} from 'utils/settings'

const getNewTask = () => ({
    id: undefined,
    task_type: 'translation',
    entry_date: formatDateForApi(new Date()),
    confirmed_date: null,
    issue_date: null,
    task_date: null,
    print_date: null,
    source_language: null,
    target_languages: [],
    status_last_change_date: null,
    hours_worked: 0,
    amount_to_pay: 0,
    amount_paid: 0,
    fees_to_pay: 0,
    payment_date: null,
    task_id: "",
    status: "draft",
    customer: null,
    translator: null,
    files: [],
    activity: [],
    bids: [],
    price: 0,
    tax_amount: 0,
    total_price: 0,
})

const getNewFile = () => ({
    filename: '',
    size: '',
    extension: '',
    source_language: '',
    target_language: '',
    status: 0,
    translator: null,
    doc_type: '',
    unit_price: 0,
    qty: 1,
    price: 0,
    tax_percentage: 19,
    tax_amount: 0,
    total_price: 0,
})

const getNewInterpretation = () => ({
    source_language: '',
    target_language: '',
    translator: null,
})

const getNewInterpretationBilling = () => ({
    title: '',
    notes: '',
    amount: 0,
    amount_type: 'Stk',
    unit_price: 0,
    tax_percentage: 19,
    total_price: 0,
})

const getNewActivity = (task_id, me, status) => ({
    task_id,
    activity_date: new Date(),
    title: '',
    description: '',
    note: '',
    note_author: me,
    is_public: true,
    is_status_change: !!status,
    previous_status: status,
    new_status: status,
    author: me
})

const fetchTask = async (taskId, setTask, setCustomer, authContext, setIsLoading, setError, setOldState, setActivity) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const task = await authGetRequest(`/admin/tasks/${taskId}`)

        if(!task.data.code) {
            setTask(task.data)
            setOldState(task.data)
            if(task.data.customer) {
                const customer = await fetchUser(task.data.customer.id, authContext)
                if(customer) {
                    setCustomer({
                        user: customer,
                        profile: customer.profiles.find(p => p.role === 'customer')
                    })
                }
            }

            const activity = await fetchActivity(task.data.id, authContext)
            if(activity !== undefined) {
                setActivity(activity)
            }
        }
        else {
            switch(task.data.code) {
                case 404:
                case 400:
                    console.log(task)
                    throw new Error('Der Auftrag wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Auftrags ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setTask(undefined)
        setOldState(setOldState)
        setError(e.message)
    }
    setIsLoading(false)
}

const fetchUser = async (id, authContext) => {
    const { authGetRequest } = authContext

    const user = await authGetRequest(`/admin/users/${id}`)

    if(!user.data.code) {
        return user.data
    }
    else {
        switch(user.data.code) {
            case 404:
            case 400:
                return undefined
            default:
            case 500:
                throw new Error('Beim Laden des Kontakts ist ein Fehler aufgetreten')
        }
    }
}

const fetchActivity = async (taskId, authContext) => {
    const { authGetRequest } = authContext

    const activity = await authGetRequest(`/admin/activity/by-task/${taskId}`)

    if(!activity.data.code) {
        return activity.data
    }
    else {
        switch(activity.data.code) {
            case 404:
            case 400:
                return undefined
            default:
            case 500:
                throw new Error('Beim Laden des Verlaufs ist ein Fehler aufgetreten')
        }
    }
}

const fetchUsers = async (setUsers, { sortBy, limit, page, q, userType, lang1, lang2 }, setIsLoading, authContext) => {
    const { authGetRequest } = authContext
    // setIsLoading(true)
    setUsers([])
    const user = await authGetRequest(`/admin/users?sortBy=${sortBy}&limit=${limit}&page=${page}&q=${q}&userType=${userType}&lang1=${lang1}&lang2=${lang2}`)
    setUsers(user.data?.results || [])
    // setIsLoading(false)
}


function hasParentClass(child, classname){
    if(child.className.split(' ').indexOf(classname) >= 0) return true;
    try {
        return child.parentNode && hasParentClass(child.parentNode, classname);
    } catch(TypeError){
        return false;
    }
}

const TaskController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()

    const [task, setTask] = useState()
    const [activity, setActivity] = useState([])
    const [oldState, setOldState] = useState()
    const [saveStatus, setSaveStatus] = useState("INIT")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [validationErrors, setValidationErrors] = useState({})
    const { taskId } = useParams()
    const history = useHistory()
    const isNew = taskId === 'new'
    const [customer, setCustomer] = useState()
    const [customerSuggestions, setCustomerSuggestions] = useState([])
    const [showTab, setShowTab] = useState('general')
    const [showFileUpload, setShowFileUpload] = useState(false)
    const [tmpActivityForm, setTmpActivityForm] = useState()
    const [activityCurrentlyEditingIndex, setActivityCurrentlyEditingIndex] = useState(undefined)
    const [uploadInvoiceIndex, setUploadInvoiceIndex] = useState(undefined)
    const [shouldAutoSave, setShouldAutoSave] = useState(false)
    const [showInterpretationInvoiceUpload, setShowInterpretationInvoiceUpload] = useState(false)

    const [focusElement, setFocusElement] = useState()

    const languages = processAppSettingsKeyValueArray(appSettings.appSettings.languages)
    const taskTypes = processAppSettingsKeyValueArray(appSettings.appSettings.task_types)
    const statuses = processStaticSettingsObject(appSettings.staticSettings.statuses)

    const closeShowCustomerSuggestionsDropdown = (e) => {
        if(!hasParentClass(e.target, 'show-customer-suggestions')) {
            setCustomerSuggestions([])
        }
    }

    const closeShowTranslatorSuggestionsDropdown = (e) => {
        if(!hasParentClass(e.target, 'show-translator-suggestions') && task) {
            setTask((task) => ({
                ...task,
                files: task.files.map(file => ({
                    ...file,
                    translator_suggestions: []
                }))
            }))
        }
    }

    useEffect(() => {
        window.addEventListener('click', closeShowCustomerSuggestionsDropdown)
        window.addEventListener('click', closeShowTranslatorSuggestionsDropdown)        

        return () => {
            window.removeEventListener('click', closeShowCustomerSuggestionsDropdown)
            window.removeEventListener('click', closeShowTranslatorSuggestionsDropdown)
        }
    }, [])

    useEffect(() => {
        if(taskId === 'new') {
            setTask(getNewTask())
        }
        else if(taskId) {
            fetchTask(taskId, setTask, setCustomer, authContext, setIsLoading, setError, setOldState, setActivity)
        }
        else {
            setTask(undefined)
            setOldState(undefined)
            setError('Der Auftrag wurde entweder gelöscht oder konnte nicht gefunden werden.')
        }
    }, [taskId, authContext])

    useEffect(() => {
        if(shouldAutoSave) {
            autoSaveTask()
            setShouldAutoSave(false)

        }
    }, [shouldAutoSave])

    const autoSaveTask = async () => {
        setSaveStatus('SAVING')
        try {
            let res

            const actionAuthor = {
                id: authContext.currentUser.id,
                name: authContext.currentUser.is_company ? authContext.currentUser.company_name : `${authContext.currentUser.first_name} ${authContext.currentUser.last_name}`,
                activity_date: formatDateForApi(new Date()),
            }
            
            const taskToSave = {
                ...task,
                update: actionAuthor
            }
            res = await authContext.authPatchRequest(`/admin/tasks/${task.id}`, taskToSave)
            setTask(taskToSave)
            
            if(!res.data.code) {
                setSaveStatus('SAVED')
            }
            else {
                setSaveStatus('FAILED')
            }
        }
        catch(e) {
            setSaveStatus('FAILED')
        }
    }

    const revertAutoSave = async () => {
        setSaveStatus('SAVING')
        try {
            let res

            const actionAuthor = {
                id: authContext.currentUser.id,
                name: authContext.currentUser.is_company ? authContext.currentUser.company_name : `${authContext.currentUser.first_name} ${authContext.currentUser.last_name}`,
                activity_date: formatDateForApi(new Date()),
            }
            
            const taskToSave = {
                ...oldState,
                update: actionAuthor
            }
            res = await authContext.authPatchRequest(`/admin/tasks/${task.id}`, taskToSave)
            setTask(taskToSave)
            
            if(!res.data.code) {
                setSaveStatus('UNDO')
            }
            else {
                setSaveStatus('FAILED')
            }
        }
        catch(e) {
            setSaveStatus('FAILED')
        }
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        try {
            let res

            const actionAuthor = {
                id: authContext.currentUser.id,
                name: authContext.currentUser.is_company ? authContext.currentUser.company_name : `${authContext.currentUser.first_name} ${authContext.currentUser.last_name}`,
                activity_date: formatDateForApi(new Date()),
            }

            if(task.id) {
                const taskToSave = {
                    ...task,
                    update: actionAuthor
                }
                res = await authContext.authPatchRequest(`/admin/tasks/${task.id}`, taskToSave)
                setTask(taskToSave)
            }
            else {
                const taskToSave = {
                    ...task,
                    creation: actionAuthor
                }
                res = await authContext.authPostRequest(`/admin/tasks`, taskToSave)
            }

            if(!res.data.code) {
                toast.success(`Der Auftrag wurde ${isNew ? 'erstellt' : 'aktualisiert'}`)
                history.push(`/admin/tasks/${res.data.id}`)
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                        break
                    case 401:
                        throw new Error("Ihre Session wurde beendet. Bitte loggen Sie sich erneut ein")
                        break
                    default:
                    case 500:
                        throw new Error('Beim Laden des Auftrags ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    const setTaskAndSave = (taskToSave) => {
        setTask(taskToSave)

        if(task.id) {
            setShouldAutoSave(true)
        }
    }

    const updateTask = (updates) => {
        setTaskAndSave((task) => ({
            ...task,
            ...updates,
        }))
    }

    const searchCustomers = async (q) => {
        fetchUsers(setCustomerSuggestions, { 
            sortBy: 'first_name', 
            limit: 10, 
            page: 1, 
            q, 
            userType: 'customer', 
            lang1: '',
            lang2: '',
            // lang1: task?.source_language, 
            // lang2: task?.target_languages?.[0]
        }, setIsLoading, authContext)
    }

    const searchTranslatorsForFile = (i, q) => {
        fetchUsers((translator_suggestions) => {
            console.log(translator_suggestions)
            updateFile(i, {
                translator_suggestions
            })
        }, { 
            sortBy: 'first_name', 
            limit: 10, 
            page: 1, 
            q, 
            userType: 'translator',
            lang1: task?.files[i]?.source_language, 
            lang2: task?.files[i]?.target_language,
        }, setIsLoading, authContext)
    }

    const searchTranslatorsForInterpretation = (i, q) => {
        fetchUsers((translator_suggestions) => {
            updateInterpretation(i, {
                translator_suggestions
            })
        }, { 
            sortBy: 'first_name', 
            limit: 10, 
            page: 1, 
            q, 
            userType: 'translator',
            lang1: task?.interpretations[i]?.source_language, 
            lang2: task?.interpretations[i]?.target_language,
        }, setIsLoading, authContext)
    }

    const assignCustomer = (customer) => {
        setCustomerSuggestions([])
        if(customer) {
            const profile = customer.profiles.find(p => p.role === 'customer')
            setTaskAndSave((task) => ({
                ...task,
                customer: {
                    id: customer.id,
                    name: customer.is_company ? customer.company_name : `${customer.first_name} ${customer.last_name}`,
                    role: !customer.is_company ? '' : customer.company_type === 'school' ? 'Sprachschule' : customer.company_type === 'government' ? 'Staatliche Institution' : 'Firma',
                },
            }))
            setCustomer({
                user: customer,
                profile
            })
        }
        else {
            setTaskAndSave((task) => ({
                ...task,
                customer: null,
            }))
            setCustomer(undefined)
        }
    }

    const assignTranslatorToFile = (i, translator) => {
        let activityTitle

        if(translator) {
            const name = translator.is_company ? translator.company_name : `${translator.first_name} ${translator.last_name}`
            activityTitle = `${name} wurde als Übersetzer:in hinzugefügt`

            updateFile(i, {
                translator: {
                    id: translator.id,
                    name,
                    role: !translator.is_company ? '' : translator.company_type === 'school' ? 'Sprachschule' : translator.company_type === 'government' ? 'Staatliche Institution' : 'Firma',
                    email: translator.email,
                    phone: translator.profiles.find(p => p.role === 'translator')?.personal_information?.phones?.[0],
                },
                translator_suggestions: []
            })
        }
        else {
            const name = task.files[i]?.translator?.name
            if(name) {
                activityTitle = `${name} wurde als Übersetzer:in entfernt`
            }
            
            updateFile(i, {
                translator: null,
                translator_suggestions: []
            })
        }

        if(activityTitle) {
            addActivity({
                ...getNewActivity(task.id, {
                    id: authContext.currentUser.id,
                    role: 'admin',
                    name: `${authContext.currentUser.first_name} ${authContext.currentUser.last_name}`,
                }),
                title: activityTitle,
                description: activityTitle,
            })
        }
    }

    const assignTranslatorToInterpretation = (i, translator) => {
        let activityTitle

        if(translator) {
            const name = translator.is_company ? translator.company_name : `${translator.first_name} ${translator.last_name}`
            activityTitle = `${name} wurde als Dolmetscher:in hinzugefügt`

            updateInterpretation(i, {
                translator: {
                    id: translator.id,
                    name,
                    role: !translator.is_company ? '' : translator.company_type === 'school' ? 'Sprachschule' : translator.company_type === 'government' ? 'Staatliche Institution' : 'Firma',
                    email: translator.email,
                    phone: translator.profiles.find(p => p.role === 'translator')?.personal_information?.phones?.[0],
                },
                translator_suggestions: []
            })
        }
        else {
            const name = task.interpretations[i]?.translator?.name
            if(name) {
                activityTitle = `${name} wurde als Dolmetscher:in entfernt`
            }
            
            updateInterpretation(i, {
                translator: null,
                translator_suggestions: []
            })
        }

        if(activityTitle) {
            addActivity({
                ...getNewActivity(task.id, {
                    id: authContext.currentUser.id,
                    role: 'admin',
                    name: `${authContext.currentUser.first_name} ${authContext.currentUser.last_name}`,
                }),
                title: activityTitle,
                description: activityTitle,
            })
        }
    }

    const addFile = (file) => {
        setTaskAndSave((task) => ({
            ...task,
            files: [
                ...task.files,
                file
            ]
        }))
    }

    const updateFile = (i, updates) => {
        setTaskAndSave((task) => ({
            ...task,
            files: task.files.map((file, u) => {
                if(u === i) {
                    return {
                        ...file,
                        ...updates
                    }
                }
                else {
                    return file
                }
            })
        }))
    }

    const removeFile = (i) => {
        setTaskAndSave((task) => ({
            ...task,
            files: task.files.filter((f, u) => u !== i),
        }))
    }

    const updateAndRecalculateFilePrice = (i, updates) => {
        const file = {
            ...task.files[i],
            ...updates
        }

        const price = file.unit_price * file.qty
        const tax_amount = price / 100 * file.tax_percentage
        const total_price = price + tax_amount

        const task_updates = {
            price: 0,
            tax_amount: 0,
            total_price: 0,
        }

        task.files.forEach((file, u) => {
            if(u === i) {
                task_updates.price += price
                task_updates.tax_amount += tax_amount
                task_updates.total_price += total_price
            }
            else {
                task_updates.price += file.price
                task_updates.tax_amount += file.tax_amount
                task_updates.total_price += file.total_price
            }
        })

        if(!task.payment_is_modified) {
            task_updates.payment_price = task_updates.price
            task_updates.payment_tax_amount = task_updates.tax_amount
            task_updates.payment_total_price = task_updates.payment_price + task_updates.payment_tax_amount
        }

        setTaskAndSave((task) => ({
            ...task,
            ...task_updates,
            files: task.files.map((file, u) => {
                if(u === i) {
                    return {
                        ...file,
                        ...updates,
                        price,
                        tax_amount,
                        total_price
                    }
                }
                else {
                    return file
                }
            })
        }))
    }

    const addInterpretation = (interpretation) => {
        setTaskAndSave((task) => ({
            ...task,
            interpretations: [
                ...task.interpretations,
                getNewInterpretation(),
            ]
        }))
    }

    const updateInterpretation = (i, updates) => {
        setTaskAndSave((task) => ({
            ...task,
            interpretations: task.interpretations.map((interpretation, u) => {
                if(u === i) {
                    return {
                        ...interpretation,
                        ...updates
                    }
                }
                else {
                    return interpretation
                }
            })
        }))
    }

    const removeInterpretation = (i) => {
        setTaskAndSave((task) => ({
            ...task,
            interpretations: task.interpretations.filter((f, u) => u !== i),
        }))
    }


    const addInterpretationBilling = () => {
        setTaskAndSave((task) => ({
            ...task,
            interpretation_billings: [
                ...task.interpretation_billings,
                getNewInterpretationBilling()
            ]
        }))
    }
        
    const removeInterpretationBilling = (i) => {
        setTaskAndSave((task) => ({
            ...task,
            interpretation_billings: task.interpretation_billings.filter((f, u) => u !== i),
        }))
    }
    
    const updateInterpretationBilling = (i, updates) => {
        setTaskAndSave((task) => ({
            ...task,
            interpretation_billings: task.interpretation_billings.map((interpretation_billing, u) => {
                if(u === i) {
                    return {
                        ...interpretation_billing,
                        ...updates
                    }
                }
                else {
                    return interpretation_billing
                }
            })
        }))
    }
        
    const updateAndRecalculateInterpretationBilling = (i, updates) => {
        const task_updates = {
            price: 0,
            tax_amount: 0,
            total_price: 0
        }

        const interpretation_billings = task.interpretation_billings.map((interpretation_billing, u) => {
            let new_interpretation_billing = interpretation_billing

            if(u === i) {
                new_interpretation_billing = {
                    ...interpretation_billing,
                    ...updates
                }
            }

            const price = new_interpretation_billing.unit_price * new_interpretation_billing.amount
            const tax_amount = price * new_interpretation_billing.tax_percentage / 100
            const total_price = price + tax_amount

            task_updates.price += price
            task_updates.tax_amount += tax_amount
            task_updates.total_price += total_price

            return {
                ...new_interpretation_billing,
                total_price,
            }
        })


        if(!task.payment_is_modified) {
            task_updates.payment_price = task_updates.price
            task_updates.payment_tax_amount = task_updates.tax_amount
            task_updates.payment_total_price = task_updates.payment_price + task_updates.payment_tax_amount
        }

        setTaskAndSave((task) => ({
            ...task,
            ...task_updates,
            interpretation_billings
        }))
    }

    const getUploadParams = ({ meta }) => { 
        return { 
            url: `${process.env.REACT_APP_API_URL}/v1/admin/files/task-file`
        } 
    }
  
    // called every time a file's `status` changes
      const handleChangeStatus = ({ meta, file, xhr }, status) => { 
        if(status === 'done') {
            const { key } = JSON.parse(xhr.response)

            addFile({ 
                ...getNewFile(),
                filename: meta.name,
                size: meta.size,
                real_filename: key,
                extension: '.' + meta.name.split('.').pop().toUpperCase(),
            })
            setShowFileUpload(false)
        }
      }
      
      // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files) => { console.log(files.map(f => f.meta)) }

    const getInvoiceUploadParams = ({ meta }) => { 
        return { 
            url: `${process.env.REACT_APP_API_URL}/v1/admin/files/invoice`
        } 
    }

    const handleInvoiceChangeStatus = ({ meta, file, xhr }, status) => { 
        if(status === 'done') {
            const { key } = JSON.parse(xhr.response)

            updateFile(uploadInvoiceIndex, {
                invoice_file: meta.name,
                invoice_real_file: key,
            })
            setUploadInvoiceIndex(undefined)
        }
    }

    const handleInvoiceSubmit = (files) => { console.log(files.map(f => f.meta)) }

    const handleInterpretationInvoiceChangeStatus = ({ meta, file, xhr }, status) => { 
        if(status === 'done') {
            const { key } = JSON.parse(xhr.response)

            updateTask({
                interpretation_invoice: {
                    invoice_file: meta.name,
                    invoice_real_file: key,
                }
            })
            setShowInterpretationInvoiceUpload(false)
        }
    }

    const handleInterpretationInvoiceSubmit = (files) => { console.log(files.map(f => f.meta)) }

    const notifyTaskAccepted = async () => {
        await authContext.authPostRequest(`/admin/tasks/${task.id}/notifyAccepted`)
    }

    const persistActivity = () => {
        const status = tmpActivityForm.is_status_change ? tmpActivityForm.new_status : task.status
        addActivity(tmpActivityForm, status)
        setTmpActivityForm(undefined)
    }

    const addActivity = async (activity, status) => {
        if(task.status === 'draft' && status === 'open') {
            notifyTaskAccepted()
        }

        setTaskAndSave((prevTask) => ({
            ...prevTask,
            status: status ? status : prevTask.status,
        }))

        const res = await authContext.authPostRequest(`/admin/activity`, activity)
        
        if(!res.data.code) {
            setSaveStatus('SAVED')
            setActivity(prevActivity => {
                return [
                    res.data,
                    ...prevActivity
                ]
            })
        }
        else {
            setSaveStatus('FAILED')
        }
    }

    const updateActivity = async (id, updates) => {
        const res = await authContext.authPatchRequest(`/admin/activity/${id}`, updates)
        
        if(!res.data.code) {
            setSaveStatus('SAVED')
            setActivity(prevActivity => {
                return prevActivity.map((activityItem) => {
                    if(activityItem.id === id) {
                        return res.data
                    }
                    else {
                        return activityItem
                    }
                })
            })
        }
        else {
            setSaveStatus('FAILED')
        }
    }

    const removeActivity = async (id) => {
        const res = await authContext.authDeleteRequest(`/admin/activity/${id}`)

        if(!res.data.code) {
            setSaveStatus('SAVED')
            setActivity(prevActivity => {
                return prevActivity.filter((activityItem) => activityItem.id !== id)
            })
        }
        else {
            setSaveStatus('FAILED')
        }
    }

    return {
        currentUser: authContext.currentUser,
        task,
        activity,
        oldState,
        saveStatus,
        updateTask,
        isNew,
        isLoading,
        error,
        handleSubmitForm,
        validationErrors,
        languages,
        statuses,
        taskTypes,
        showTab, 
        setShowTab,
        customer,
        searchCustomers,
        customerSuggestions,
        assignCustomer,
        searchTranslatorsForFile,
        assignTranslatorToFile,
        addFile,
        updateFile,
        removeFile,
        showFileUpload, 
        setShowFileUpload,
        updateAndRecalculateFilePrice,
        getUploadParams,
        handleChangeStatus,
        handleSubmit,
        tmpActivityForm, 
        setTmpActivityForm,
        getNewActivity,
        persistActivity,
        updateActivity,
        removeActivity,
        activityCurrentlyEditingIndex, 
        setActivityCurrentlyEditingIndex,
        uploadInvoiceIndex, 
        setUploadInvoiceIndex,
        getInvoiceUploadParams,
        handleInvoiceChangeStatus,
        handleInvoiceSubmit,
        addInterpretation,
        updateInterpretation,
        removeInterpretation,
        addInterpretationBilling,
        removeInterpretationBilling,
        updateInterpretationBilling,
        updateAndRecalculateInterpretationBilling,
        assignTranslatorToInterpretation,
        searchTranslatorsForInterpretation,
        revertAutoSave,
        showInterpretationInvoiceUpload, 
        setShowInterpretationInvoiceUpload,
        handleInterpretationInvoiceChangeStatus,
        handleInterpretationInvoiceSubmit,
        focusElement,
        setFocusElement,
    }
}

export default TaskController