// @ts-nocheck
import { apiToDisplayDate, displayToApiDate, apiToUiDate } from 'utils/datetime'

const TaskPrint = ({ task, customer, languages, statuses }) => {
    
    if(!task) {
        return false
    }

    return (
        <div>
            <div className="text-end mb-3">
                <img src="/assets/images/logo-dark.png?v=1" alt="" height="100" />
            </div>
            <h5>Typ des Auftrages</h5>
            <div className="row">
                <div className="col-6">
                    { task.task_type === 'translation' && 'Übersetzung' }
                    { task.task_type === 'interpretation' && 'Dolmetschen' }
                    { task.task_type === 'lesson' && 'Sprachunterricht' }
                </div>
                <div className="col-3 text-end">
                    Nummer:
                </div>
                <div className="col-3">
                    { task.task_id }
                </div>
            </div>
            <hr />
            <h5>Auftragsdaten</h5>
            <div className="row">
                <div className="col-2">
                    Eingang:
                </div>
                <div className="col-4">
                    { apiToUiDate(task.entry_date) }
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    Erteilt:
                </div>
                <div className="col-4">
                    { apiToUiDate(task.issue_date) }
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    Bestätigt:
                </div>
                <div className="col-4">
                    { apiToUiDate(task.confirmed_date) }
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    Auftragsdatum:
                </div>
                <div className="col-4">
                    { apiToUiDate(task.task_date) }
                </div>
                <div className="col-3 text-end">
                    Druckdatum:
                </div>
                <div className="col-3">
                    { apiToUiDate(task.print_date) }
                </div>
            </div>
            <hr />
            <h5>Auftraggeber und Ansprechpartner</h5>
            { customer && customer.user.is_company && (
                <>
                    <div className="row">
                        <div className="col-2">
                            Firma:
                        </div>
                        <div className="col-10">
                            { customer.user.company_name }
                        </div>
                    </div>
                </>
            ) }
            { customer && !customer.user.is_company && (
                <>
                    <div className="row">
                        <div className="col-2">
                            Name:
                        </div>
                        <div className="col-10">
                            { customer.user.first_name } { customer.user.last_name }
                        </div>
                    </div>
                    { customer.profile.company && (
                        <div className="row">
                            <div className="col-2">
                                Firma:
                            </div>
                            <div className="col-10">
                                { customer.profile.company.name }
                            </div>
                        </div>
                    ) }
                </>
            ) }
            <hr />
            <h5>Übersetzer:in / Dolmetscher:in</h5>
            { task.task_type === 'translation' && task.files.map((file, i) => (
                <>
                    <div className="row" key={i}>
                        <div className="col-2">
                            Name:
                        </div>
                        <div className="col-6">
                            { file.translator?.name }
                        </div>
                        <div className="col-4">
                            { file.translator?.phone }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <span className="ps-2">
                                Sprache(n)
                            </span>
                        </div>
                        <div className="col-10">
                            { [
                                languages.find(l => l.key === file.source_language)?.value,
                                languages.find(l => l.key === file.target_language)?.value,
                            ].filter(l => l).join(', ') }
                        </div>
                    </div>
                </>
            )) }
            { ['interpretation', 'lesson'].includes(task.task_type) && task.interpretations.map((interpretation, i) => (
                <>
                    <div className="row" key={i}>
                        <div className="col-2">
                            Name:
                        </div>
                        <div className="col-6">
                            { interpretation.translator?.name }
                        </div>
                        <div className="col-4">
                            { interpretation.translator?.phone }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <span className="ps-2">
                                Sprache(n)
                            </span>
                        </div>
                        <div className="col-10">
                            { [
                                languages.find(l => l.key === interpretation.source_language)?.value,
                                languages.find(l => l.key === interpretation.target_language)?.value,
                            ].filter(l => l).join(', ') }
                        </div>
                    </div>
                </>
            )) }
            <hr />
            <h5>Auftragsinfo</h5>
            <div className="row">
                <div className="col-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Datum</th>
                                <th>Betreff / Status</th>
                                <th>Wer</th>
                                <th>Beschreibung</th>
                            </tr>
                        </thead>
                        <tbody>
                            { [...task.activity].reverse().map((activity, i) => (
                                <tr key={i}>
                                    <td>{ apiToUiDate(activity.activity_date) }</td>
                                    <td>
                                        { !activity.is_status_change && activity.title }
                                        { activity.is_status_change && (
                                            <div>
                                                { statuses.find(s => s.key === activity.previous_status)?.value }{" "}
                                                <i className="dripicons-arrow-thin-right" />{" "}
                                                { statuses.find(s => s.key === activity.new_status)?.value }
                                            </div>
                                        ) }
                                    </td>
                                    <td>
                                        { activity.author?.name }
                                    </td>
                                    <td>
                                        { activity.description }
                                    </td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TaskPrint