import React, { useContext, useState, useEffect } from "react"
import { postRequest, putRequest, patchRequest, getRequest, deleteRequest } from "clients/ApiClient"

import AppLoadingScreen from "components/AppLoadingScreen"

const AppSettingsContext = React.createContext<AppSettingsContextType | undefined>(undefined)

export const useAppSettings = () => {
    return useContext(AppSettingsContext)
}

interface AppSettingsContextType {
    appSettings: any; // change this
    setAppSettings: React.Dispatch<React.SetStateAction<any | undefined>>;
    staticSettings: any; // change this
}

interface AppSettingsProviderProps {
    children: React.ReactElement;
}
export const AppSettingsProvider = ({ children }: AppSettingsProviderProps) => {
    const [appSettings, setAppSettings] = useState<any>({})
    const [staticSettings, setStaticSettings] = useState({})
    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    useEffect(() => {
        setIsInitialized(false)
        initializeApp()
    }, [])

    const initializeApp = async () => {
        await Promise.all([
            getRequest('/static/app-settings')
                .then((data) => {
                    setStaticSettings(data.data)
                }),
            getRequest('/settings/app-settings')
                .then((data) => {
                    setAppSettings(data.data)
                })
        ])
        setTimeout(() => {
            setIsInitialized(true)
        }, 200)
    }
    
    const value: any = {
        appSettings,
        setAppSettings,
        staticSettings
    }

    if(!isInitialized) {
        return <AppLoadingScreen />
    }

    return (
        <AppSettingsContext.Provider value={value}>
            { children }
        </AppSettingsContext.Provider>
    )
}