// @ts-nocheck
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"
import {
    processAppSettingsKeyValueArray,
    processStaticSettingsObject,
} from 'utils/settings'

import { apiToDisplayDate } from 'utils/datetime'

import { getRequest } from "clients/ApiClient"

const AdminCalendarController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()
    console.log({
        appSettings
    })
    const { authGetRequest } = authContext

    const languages = processAppSettingsKeyValueArray(appSettings.appSettings.languages)

    const [isLoading, setIsLoading] = useState(false)

    const fetchTasks = async ({ taskDateFrom, taskDateTo, setTasks }) => {
        const task = await authGetRequest(`/admin/tasks?taskDateFrom=${taskDateFrom}&taskDateTo=${taskDateTo}`)
        setTasks(task.data)
    }

    const fetchEvents = (fetchInfo, successCallback, faileCallback) => {
        fetchTasks({
            taskDateFrom: apiToDisplayDate(fetchInfo.start),
            taskDateTo: apiToDisplayDate(fetchInfo.end),
            setTasks: (tasks) => {
                successCallback(tasks.results?.length ? tasks.results.map((task) => {
                    const langs = ["interpretation", "lesson"].includes(task.task_type) ? 
                        [...new Set([...task.interpretations.map(f => f.source_language).filter(l => l), ...task.interpretations.map(f => f.target_language).filter(l => l)])].map(f => languages.find(l => l.key === f)?.value).join(' - ') :
                        [...new Set([...task.files.map(f => f.source_language).filter(l => l), ...task.files.map(f => f.target_language).filter(l => l)])].map(f => languages.find(l => l.key === f)?.value).join(' - ') 

                    const translators = ["interpretation", "lesson"].includes(task.task_type) ? 
                        [...new Set(task.interpretations.map(f => f.translator?.name).filter(l => l))].join(' - ') :
                        [...new Set(task.files.map(f => f.translator?.name).filter(l => l))].join(' - ') 

                    return {
                        title: langs,
                        start: task.task_date,
                        className: `text-start ${["interpretation", "lesson"].includes(task.task_type) ? 'bg-success' : 'bg-info'}`,
                        url: `/admin/tasks/${task.id}`,
                        extendedProps: {
                            task,
                            langs,
                            translators,
                        }
                    }
                }) : [])
            }, 
        })
    }

    return {
        fetchEvents,
        isLoading,
        languages
    }
}

export default AdminCalendarController