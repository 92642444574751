// @ts-nocheck
import React from 'react'
import { Link, useHistory } from "react-router-dom"

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import TaskController from 'controllers/admin/Task.controller'
import TaskSkeleton from 'screens/skeletons/admin/Task.skeleton'
import TaskPrint from 'screens/prints/admin/Task.print'
import GeneralError from 'screens/errors/GeneralError'

import { apiToDisplayDate, displayToApiDate, apiToUiDate } from 'utils/datetime'
import { humanFileSize } from 'utils/files'
import { formatMoney } from 'utils/number'

const Task = (props) => {
    
    const {
        currentUser,
        isNew,
        task,
        activity,
        oldState,
        saveStatus,
        updateTask,
        isLoading,
        error,
        handleSubmitForm,
        validationErrors,
        languages,
        taskTypes,
        statuses,
        showTab, 
        setShowTab,
        customer,
        searchCustomers,
        customerSuggestions,
        assignCustomer,
        searchTranslatorsForFile,
        assignTranslatorToFile,
        addFile,
        updateFile,
        removeFile,
        showFileUpload, 
        setShowFileUpload,
        updateAndRecalculateFilePrice,
        getUploadParams,
        handleChangeStatus,
        handleSubmit,
        tmpActivityForm, 
        setTmpActivityForm,
        getNewActivity,
        persistActivity,
        removeActivity,
        updateActivity,
        activityCurrentlyEditingIndex, 
        setActivityCurrentlyEditingIndex,
        uploadInvoiceIndex, 
        setUploadInvoiceIndex,
        getInvoiceUploadParams,
        handleInvoiceChangeStatus,
        handleInvoiceSubmit,
        addInterpretation,
        updateInterpretation,
        removeInterpretation,
        addInterpretationBilling,
        removeInterpretationBilling,
        updateInterpretationBilling,
        updateAndRecalculateInterpretationBilling,
        assignTranslatorToInterpretation,
        searchTranslatorsForInterpretation,
        revertAutoSave,
        showInterpretationInvoiceUpload, 
        setShowInterpretationInvoiceUpload,
        handleInterpretationInvoiceChangeStatus,
        handleInterpretationInvoiceSubmit,
        focusElement,
        setFocusElement,
    } = TaskController(props)

    const history = useHistory()

    return (
        <>
            <div className="d-print-none">
                { isLoading && <TaskSkeleton /> }
                { error && (
                    <GeneralError
                        title={ isNew ? 'Auftrag / Anfrage erstellen' : 'Auftrag / Anfrage aktualisieren' }
                    >{ error }</GeneralError>
                ) }
                { task && (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <h4 className="page-title"><strong className="text-black">Verwaltung</strong> / { isNew ? 'Auftrag / Anfrage erstellen' : 'Auftrag / Anfrage aktualisieren' }</h4>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-2 d-print-none">
                                            { saveStatus !== "INIT" && (
                                                <div className="float-end">
                                                    { saveStatus === "SAVING" && "Wird gespeichert..." }
                                                    { saveStatus === "SAVED" && (
                                                        <span>
                                                             <i className="dripicons-checkmark text-success me-2" />
                                                             Gespeichert
                                                        </span>
                                                    ) }
                                                    { saveStatus === "FAILED" && (
                                                        <span>
                                                            <i className="dripicons-warning text-danger me-2" />
                                                            Die Änderungen konnten nicht gespeichert werden
                                                        </span>
                                                    ) }
                                                    { saveStatus === "UNDO" && "Alle Änderungen wurden rückgängig gemacht" }
                                                    { saveStatus !== "UNDO" && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-light btn-sm btn-icon ms-2"
                                                            onClick={ () => {
                                                                revertAutoSave()
                                                            } }
                                                        >
                                                            <i className="mdi mdi-undo" />
                                                        </button>
                                                    ) }
                                                </div>
                                            ) }
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary rounded-pill px-2"
                                                onClick={ history.goBack }
                                            >
                                                <i className="dripicons-arrow-thin-left" />
                                            </button>
                                        </div>
                                        <form action="#" onSubmit={ handleSubmitForm }>
                                            <ul className="nav nav-tabs nav-bordered mb-3 d-print-none">
                                                <li className="nav-item">
                                                    <a
                                                        href='#'
                                                        className={ `nav-link rounded-0 ${showTab === 'general' ? 'active' : ''}` }
                                                        onClick={ (e) => {
                                                            e.preventDefault();
                                                            setShowTab('general')
                                                        } }
                                                    >
                                                        Allgemein
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        href='#'
                                                        className={ `nav-link rounded-0 ${showTab === 'payments' ? 'active' : ''}` }
                                                        onClick={ (e) => {
                                                            e.preventDefault();
                                                            setShowTab('payments')
                                                        } }
                                                    >
                                                        Zahlungsinformationen
                                                    </a>
                                                </li>
                                                { task.id && (
                                                    <>
                                                        <li className="nav-item">
                                                            <a
                                                                href='#'
                                                                className={ `nav-link rounded-0 ${showTab === 'activity' ? 'active' : ''}` }
                                                                onClick={ (e) => {
                                                                    e.preventDefault();
                                                                    setShowTab('activity')
                                                                } }
                                                            >
                                                                Verlauf
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                href='#'
                                                                className={ `nav-link rounded-0 ${showTab === 'bids' ? 'active' : ''}` }
                                                                onClick={ (e) => {
                                                                    e.preventDefault();
                                                                    setShowTab('bids')
                                                                } }
                                                            >
                                                                Bewerbungen
                                                            </a>
                                                        </li>
                                                    </>
                                                ) }
                                            </ul>
                                            <div className="tab-content">
                                                <div
                                                    className={ `tab-pane d-print-block ${showTab === 'general' && 'active'}` }
                                                >
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="task_id"
                                                                    className="form-label"
                                                                >
                                                                    Auftragsnummer
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="task_id"
                                                                    className="form-control"
                                                                    value={ task.task_id }
                                                                    onChange={ (e) => {
                                                                        updateTask({
                                                                            task_id: e.target.value
                                                                        })
                                                                    } }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="task_status"
                                                                    className="form-label"
                                                                >
                                                                    Status
                                                                </label>
                                                                <select
                                                                    id="task_status"
                                                                    className="form-select"
                                                                    value={ task.status }
                                                                    disabled
                                                                    onChange={ (e) => {
                                                                        updateTask({
                                                                            status: e.target.value
                                                                        })
                                                                    } }
                                                                >
                                                                    <option>-Bitte auswählen-</option>
                                                                    { statuses.map((status) => (
                                                                        <option 
                                                                            key={ status.key }
                                                                            value={ status.key }
                                                                        >{ status.value }</option>
                                                                    )) }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="task_type"
                                                                    className="form-label"
                                                                >
                                                                    Typ
                                                                </label>
                                                                <select
                                                                    id="task_type"
                                                                    className="form-select"
                                                                    value={ task.task_type }
                                                                    onChange={ (e) => {
                                                                        updateTask({
                                                                            task_type: e.target.value,
                                                                            files: [],
                                                                            interpretations: [],
                                                                            interpretation_billings: [],
                                                                            interpretation_invoice: undefined,
                                                                            price: 0,
                                                                            tax_amount: 0,
                                                                            total_price: 0,
                                                                        })
                                                                    } }
                                                                >
                                                                    <option value="translation">Übersetzung</option>
                                                                    <option value="interpretation">Dolmetschen</option>
                                                                    <option value="lesson">Sprachunterricht</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card bg-light shadow-none border">
                                                                <div className="p-3 py-2">
                                                                    <h5 className="mb-3 mt-2">
                                                                        Daten
                                                                    </h5>
                                                                    <div className="row">
                                                                        <div className="col-lg-2">
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor="entry_date"
                                                                                    className="form-label"
                                                                                >
                                                                                    Eingang
                                                                                </label>
                                                                                <input
                                                                                    type="date"
                                                                                    id="entry_date"
                                                                                    className="form-control"
                                                                                    value={ apiToDisplayDate(task.entry_date) }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            entry_date: displayToApiDate(e.target.value)
                                                                                        })
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2">
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor="confirmed_date"
                                                                                    className="form-label"
                                                                                >
                                                                                    Bestätigt
                                                                                </label>
                                                                                <input
                                                                                    type="date"
                                                                                    id="confirmed_date"
                                                                                    className="form-control"
                                                                                    value={ apiToDisplayDate(task.confirmed_date) }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            confirmed_date: displayToApiDate(e.target.value)
                                                                                        })
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2">
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor="issue_date"
                                                                                    className="form-label"
                                                                                >
                                                                                    Erteilt
                                                                                </label>
                                                                                <input
                                                                                    type="date"
                                                                                    id="issue_date"
                                                                                    className="form-control"
                                                                                    value={ apiToDisplayDate(task.issue_date) }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            issue_date: displayToApiDate(e.target.value)
                                                                                        })
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2">
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor="task_date"
                                                                                    className="form-label"
                                                                                >
                                                                                    Auftragsdatum
                                                                                </label>
                                                                                <input
                                                                                    type="date"
                                                                                    id="entry_date"
                                                                                    className="form-control"
                                                                                    value={ apiToDisplayDate(task.task_date) }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            task_date: displayToApiDate(e.target.value)
                                                                                        })
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2">
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor="print_date"
                                                                                    className="form-label"
                                                                                >
                                                                                    Druckdatum
                                                                                </label>
                                                                                <input
                                                                                    type="date"
                                                                                    id="print_date"
                                                                                    className="form-control"
                                                                                    value={ apiToDisplayDate(task.print_date) }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            print_date: displayToApiDate(e.target.value)
                                                                                        })
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                                
                                                    
                                                    <h5 className="mb-3 mt-2">
                                                        Auftraggeber
                                                    </h5>

                                                    { customer ? (
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="card mb-3 shadow-none border">
                                                                    <div className="p-3">
                                                                        <div className="clearfix">
                                                                            <div className="float-end">
                                                                                <button 
                                                                                    type="button"
                                                                                    className="btn btn-light btn-icon btn-round"
                                                                                    onClick={ () => {
                                                                                        assignCustomer(undefined)
                                                                                    } }
                                                                                ><i className="uil-times" /></button>
                                                                            </div>
                                                                            <Link className="text-info d-inline-block pt-1" to={ `/admin/users/${customer.user.id}` } target="_blank">
                                                                                { customer.user.is_company ? `${customer.user.company_name} (${customer.user.company_type === 'school' ? 'Sprachschule' : customer.user.company_type === 'government' ? 'Staatliche Institution' : 'Firma' })` : `${customer.user.first_name} ${customer.user.last_name}` }
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="row">
                                                            <div className="col-4">   
                                                                <div className="mb-3">
                                                                    <div className="input-group">
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control dropdown-toggle"  
                                                                            placeholder="Kunden suchen..."
                                                                            onChange={ (e) => {
                                                                                searchCustomers(e.target.value)
                                                                            } }
                                                                        />
                                                                        <span className="input-group-text mdi mdi-magnify search-icon" />
                                                                    </div>
                                                                    { customerSuggestions.length > 0 && (
                                                                        <div
                                                                            className="dropdown-menu dropdown-menu-end show-customer-suggestions show"
                                                                        >
                                                                            { customerSuggestions.map((customerSuggestion) => (
                                                                                <a 
                                                                                    href='#' 
                                                                                    className="dropdown-item py-1 px-3"
                                                                                    onClick={ (e) => {
                                                                                        e.preventDefault()
                                                                                        assignCustomer(customerSuggestion)
                                                                                    } }
                                                                                >
                                                                                    { customerSuggestion.is_company ? `${customerSuggestion.company_name} (${customerSuggestion.company_type === 'school' ? 'Sprachschule' : customerSuggestion.company_type === 'government' ? 'Staatliche Institution' : 'Firma' })` : `${customerSuggestion.first_name} ${customerSuggestion.last_name}` }
                                                                                </a>
                                                                            )) }
                                                                        </div>    
                                                                    ) }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) }

                                                    { task.task_type === 'translation' && (
                                                        <>
                                                            <h5 className="mb-3 mt-2">
                                                                Dokumente
                                                            </h5>
                                                            <div className="table-responsive">
                                                                <table className="table table-condensed table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Datei</th>
                                                                            <th>Typ</th>
                                                                            <th>Sprachen</th>
                                                                            <th>Auftragnehmer</th>
                                                                            <th>Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        { task.files.map((file, i) => (
                                                                            <tr key={ i }>
                                                                                <td>
                                                                                    <div className="row align-items-center" style={ { width: '250px' } }>
                                                                                        <div className="col-auto">
                                                                                            <div className="avatar-sm">
                                                                                                <span className="avatar-title rounded">
                                                                                                    <a 
                                                                                                        href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                                        target="_blank"
                                                                                                        className="text-white"
                                                                                                    >
                                                                                                        { file.extension }
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col ps-0 text-truncate">
                                                                                            <div className="text-muted fw-bold">
                                                                                                <a 
                                                                                                    href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                                    target="_blank"
                                                                                                    className="text-muted text-ellipsis text-truncate d-inline-block w-100"
                                                                                                >
                                                                                                    { file.filename }
                                                                                                </a>
                                                                                            </div>
                                                                                            <p className="mb-0">{ humanFileSize(file.size) }</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <select
                                                                                        id="task_type"
                                                                                        className="form-select"
                                                                                        value={ file.doc_type }
                                                                                        onChange={ (e) => {
                                                                                            updateFile(i, {
                                                                                                doc_type: e.target.value
                                                                                            })
                                                                                        } }
                                                                                    >
                                                                                        <option>-Bitte auswählen-</option>
                                                                                        { taskTypes.map((language) => (
                                                                                            <option 
                                                                                                key={ language.key }
                                                                                                value={ language.key }
                                                                                            >{ language.value }</option>
                                                                                        )) }
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-group flex-nowrap">
                                                                                        <select
                                                                                            id={`files_${i}_source_language`}
                                                                                            className="form-select"
                                                                                            value={ file.source_language }
                                                                                            onChange={ (e) => {
                                                                                                updateFile(i, {
                                                                                                    source_language: e.target.value
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <option>-Bitte auswählen-</option>
                                                                                            { languages.map((language) => (
                                                                                                <option 
                                                                                                    key={ language.key }
                                                                                                    value={ language.key }
                                                                                                >{ language.value }</option>
                                                                                            )) }
                                                                                        </select>
                                                                                        <span className="input-group-text">
                                                                                            <i className="uil-arrow-right" />
                                                                                        </span>
                                                                                        <select
                                                                                            id={`files_${i}_target_language`}
                                                                                            className="form-select"
                                                                                            value={ file.target_language }
                                                                                            onChange={ (e) => {
                                                                                                updateFile(i, {
                                                                                                    target_language: e.target.value
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <option>-Bitte auswählen-</option>
                                                                                            { languages.map((language) => (
                                                                                                <option 
                                                                                                    key={ language.key }
                                                                                                    value={ language.key }
                                                                                                >{ language.value }</option>
                                                                                            )) }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    { file.translator ? (
                                                                                        <div className="card shadow-none border mb-0" style={ { width: '250px' } }>
                                                                                            <div className="p-0">
                                                                                                <div className="row">
                                                                                                    <div className="col text-truncate">
                                                                                                        <Link className="text-info d-inline-block w-100 p-1 ps-2 text-truncate text-ellipsis" to={ `/admin/users/${file.translator.id}` } target="_blank">
                                                                                                            { `${file.translator.name}` }
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                    <div className="col-auto">
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-light btn-icon btn-round"
                                                                                                            onClick={ () => {
                                                                                                                assignTranslatorToFile(i, undefined)
                                                                                                            } }
                                                                                                        ><i className="uil-times" /></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <div className="input-group">
                                                                                                <input 
                                                                                                    type="text" 
                                                                                                    className="form-control dropdown-toggle"  
                                                                                                    placeholder="Übersetzer:in / Dollmetscher:in suchen..."
                                                                                                    onChange={ (e) => {
                                                                                                        searchTranslatorsForFile(i, e.target.value)
                                                                                                    } }
                                                                                                />
                                                                                                <span className="input-group-text mdi mdi-magnify search-icon" />
                                                                                            </div>
                                                                                            { file.translator_suggestions?.length > 0 && (
                                                                                                <div
                                                                                                    className="dropdown-menu dropdown-menu-end show-translator-suggestions show"
                                                                                                >
                                                                                                    { file.translator_suggestions.map((translatorSuggestion) => (
                                                                                                        <a 
                                                                                                            href='#' 
                                                                                                            className="dropdown-item py-1 px-3"
                                                                                                            onClick={ (e) => {
                                                                                                                e.preventDefault()
                                                                                                                assignTranslatorToFile(i, translatorSuggestion)
                                                                                                            } }
                                                                                                        >
                                                                                                            { translatorSuggestion.is_company ? `${translatorSuggestion.company_name} (${translatorSuggestion.company_type === 'school' ? 'Sprachschule' : translatorSuggestion.company_type === 'government' ? 'Staatliche Institution' : 'Firma' })` : `${translatorSuggestion.first_name} ${translatorSuggestion.last_name}` }
                                                                                                        </a>
                                                                                                    )) }
                                                                                                </div>    
                                                                                            ) }
                                                                                        </div>
                                                                                    ) }
                                                                                </td>
                                                                                <td>
                                                                                    <div className="btn-group mb-2">
                                                                                        <button 
                                                                                            type="button"
                                                                                            className={ `btn btn-sm btn-secondary ${file.status !== 0 ? 'opacity-25' : ''}` }
                                                                                            onClick={ () => { 
                                                                                                updateFile(i, {
                                                                                                    status: 0
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <span className="opacity-0">0</span>
                                                                                        </button>
                                                                                        <button 
                                                                                            type="button"
                                                                                            className={ `btn btn-sm btn-danger ${file.status !== 1 ? 'opacity-25' : ''}` }
                                                                                            onClick={ () => { 
                                                                                                updateFile(i, {
                                                                                                    status: 1
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <span className="opacity-0">1</span>
                                                                                        </button>
                                                                                        <button 
                                                                                            type="button"
                                                                                            className={ `btn btn-sm btn-warning ${file.status !== 2 ? 'opacity-25' : ''}` }
                                                                                            onClick={ () => { 
                                                                                                updateFile(i, {
                                                                                                    status: 2
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <span className="opacity-0">2</span>
                                                                                        </button>
                                                                                        <button 
                                                                                            type="button"
                                                                                            className={ `btn btn-sm btn-success ${file.status !== 3 ? 'opacity-25' : ''}` }
                                                                                            onClick={ () => { 
                                                                                                updateFile(i, {
                                                                                                    status: 3
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <span className="opacity-0">3</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <button 
                                                                                        type="button"
                                                                                        className="btn btn-link text-muted btn-sm"
                                                                                        onClick={ () => {
                                                                                            removeFile(i)
                                                                                        } }
                                                                                    >
                                                                                        <i className="uil-times" />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )) }
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                { showFileUpload ? (
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <Dropzone
                                                                                                getUploadParams={getUploadParams}
                                                                                                onChangeStatus={handleChangeStatus}
                                                                                                onSubmit={handleSubmit}
                                                                                                accept="*"
                                                                                                maxFiles={1}
                                                                                                multiple={false}
                                                                                                SubmitButtonComponent={null}
                                                                                                inputWithFilesContent="Datei hochladen"
                                                                                                inputContent="Dateien ziehen oder zum Durchsuchen klicken"
                                                                                                classNames={ {
                                                                                                    inputLabel: 'dz-message needsclick font-18',
                                                                                                    dropzone: 'dropzone text-center needsclick'
                                                                                                } }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-auto">
                                                                                            <button 
                                                                                                type="button"
                                                                                                className="btn btn-link text-muted btn-sm"
                                                                                                onClick={ () => {
                                                                                                    setShowFileUpload(false)
                                                                                                } }
                                                                                            >
                                                                                                <i className="uil-times" />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-light"
                                                                                        onClick={ () => {
                                                                                            setShowFileUpload(true)
                                                                                        } }
                                                                                    >
                                                                                        <i className="uil-cloud-upload" />{" "}
                                                                                        Datei hochladen
                                                                                    </button>
                                                                                ) }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    
                                                                </table>
                                                            </div>
                                                        </>
                                                    ) }

                                                    { ["interpretation", "lesson"].includes(task.task_type) && (
                                                        <>
                                                            <h5 className="mb-3 mt-2">
                                                                Dolmetschereinsatz
                                                            </h5>
                                                            <div className="table-responsive">
                                                                <table className="table table-condensed table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Sprachen</th>
                                                                            <th>Auftragnehmer</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        { task.interpretations.map((interpretation, i) => (
                                                                            <React.Fragment key={ i }>
                                                                                <tr>
                                                                                    <td className="pb-1">
                                                                                        <div className="input-group flex-nowrap">
                                                                                            <select
                                                                                                id={`interpretations_${i}_source_language`}
                                                                                                className="form-select"
                                                                                                value={ interpretation.source_language }
                                                                                                onChange={ (e) => {
                                                                                                    updateInterpretation(i, {
                                                                                                        source_language: e.target.value
                                                                                                    })
                                                                                                } }
                                                                                            >
                                                                                                <option>-Bitte auswählen-</option>
                                                                                                { languages.map((language) => (
                                                                                                    <option 
                                                                                                        key={ language.key }
                                                                                                        value={ language.key }
                                                                                                    >{ language.value }</option>
                                                                                                )) }
                                                                                            </select>
                                                                                            <span className="input-group-text">
                                                                                                <i className="uil-arrow-right" />
                                                                                            </span>
                                                                                            <select
                                                                                                id={`interpretations_${i}_target_language`}
                                                                                                className="form-select"
                                                                                                value={ interpretation.target_language }
                                                                                                onChange={ (e) => {
                                                                                                    updateInterpretation(i, {
                                                                                                        target_language: e.target.value
                                                                                                    })
                                                                                                } }
                                                                                            >
                                                                                                <option>-Bitte auswählen-</option>
                                                                                                { languages.map((language) => (
                                                                                                    <option 
                                                                                                        key={ language.key }
                                                                                                        value={ language.key }
                                                                                                    >{ language.value }</option>
                                                                                                )) }
                                                                                            </select>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="pb-1">
                                                                                        { interpretation.translator ? (
                                                                                            <div className="card shadow-none border mb-0">
                                                                                                <div className="p-0">
                                                                                                    <div className="row">
                                                                                                        <div className="col text-truncate">
                                                                                                            <Link className="text-info d-inline-block w-100 p-1 ps-2 text-truncate text-ellipsis" to={ `/admin/users/${interpretation.translator.id}` } target="_blank">
                                                                                                                { `${interpretation.translator.name}` }
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                        <div className="col-auto">
                                                                                                            <button 
                                                                                                                type="button"
                                                                                                                className="btn btn-light btn-icon btn-round"
                                                                                                                onClick={ () => {
                                                                                                                    assignTranslatorToInterpretation(i, undefined)
                                                                                                                } }
                                                                                                            ><i className="uil-times" /></button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div>
                                                                                                <div className="input-group">
                                                                                                    <input 
                                                                                                        type="text" 
                                                                                                        className="form-control dropdown-toggle"  
                                                                                                        placeholder="Dollmetscher suchen..."
                                                                                                        onChange={ (e) => {
                                                                                                            searchTranslatorsForInterpretation(i, e.target.value)
                                                                                                        } }
                                                                                                    />
                                                                                                    <span className="input-group-text mdi mdi-magnify search-icon" />
                                                                                                </div>
                                                                                                { interpretation.translator_suggestions?.length > 0 && (
                                                                                                    <div
                                                                                                        className="dropdown-menu dropdown-menu-end show-translator-suggestions show"
                                                                                                    >
                                                                                                        { interpretation.translator_suggestions.map((translatorSuggestion) => (
                                                                                                            <a 
                                                                                                                href='#' 
                                                                                                                className="dropdown-item py-1 px-3"
                                                                                                                onClick={ (e) => {
                                                                                                                    e.preventDefault()
                                                                                                                    assignTranslatorToInterpretation(i, translatorSuggestion)
                                                                                                                } }
                                                                                                            >
                                                                                                                { translatorSuggestion.is_company ? `${translatorSuggestion.company_name} (${translatorSuggestion.company_type === 'school' ? 'Sprachschule' : translatorSuggestion.company_type === 'government' ? 'Staatliche Institution' : 'Firma' })` : `${translatorSuggestion.first_name} ${translatorSuggestion.last_name}` }
                                                                                                            </a>
                                                                                                        )) }
                                                                                                    </div>    
                                                                                                ) }
                                                                                            </div>
                                                                                        ) }
                                                                                    </td>
                                                                                    <td className="pb-1" style={ { borderBottom: "1px solid white" } }>
                                                                                        <button 
                                                                                            type="button"
                                                                                            className="btn btn-link text-muted btn-sm"
                                                                                            onClick={ () => {
                                                                                                removeInterpretation(i)
                                                                                            } }
                                                                                        >
                                                                                            <i className="uil-times" />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        )) }
                                                                        <tr>
                                                                            <td colSpan={3}>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-light"
                                                                                    onClick={ () => {
                                                                                        addInterpretation()
                                                                                    } }
                                                                                >
                                                                                    <i className="uil-plus" />{" "}
                                                                                    { task.task_type === "interpretation" && "Dolmetschereinsatz hinzufügen" }
                                                                                    { task.task_type === "lesson" && "Sprachunterricht hinzufügen" }
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    
                                                                </table>
                                                            </div>
                                                        </>
                                                    ) }

                                                    <div className="row">
                                                        <div className="col-8">
                                                            <div className="card bg-light mb-3 shadow-none border">
                                                                <div className="p-3">
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor="task_id"
                                                                                    className="form-label"
                                                                                >
                                                                                    Betreff
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    id="title"
                                                                                    className="form-control"
                                                                                    value={ task.title }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            title: e.target.value
                                                                                        })
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div>
                                                                                <label
                                                                                    htmlFor="description"
                                                                                    className="form-label"
                                                                                >
                                                                                    Beschreibung
                                                                                </label>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="description"
                                                                                    rows={5}
                                                                                    value={ task.description }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            description: e.target.value
                                                                                        })
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={ `tab-pane d-print-block ${showTab === 'payments' && 'active'}` }
                                                >
                                                    { task.paypal_invoice_id && (
                                                        <>
                                                            <h5 className="mb-3 mt-2">
                                                                PayPal Rechnung
                                                            </h5>
                                                            <div className="mb-3">
                                                                <a className="btn btn-dark" href={`https://www.sandbox.paypal.com/invoice/s/pay/${task.paypal_invoice_id}`} target="_blank">Rechnung Anzeigen</a>
                                                            </div>
                                                        </>
                                                    ) }
                                                    { !["interpretation", "lesson"].includes(task.task_type) && (
                                                        <>
                                                            <h5 className="mb-3 mt-2">
                                                                Dokumente
                                                            </h5>
                                                            <div className="table-responsive">
                                                                <table className="table table-condensed table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Dokument</th>
                                                                            <th>Rechnung</th>
                                                                            <th>Netto Preis / St.</th>
                                                                            <th>Menge</th>
                                                                            <th>Netto Preis</th>
                                                                            <th colSpan={2}>Umsatzsteuer</th>
                                                                            <th>Brutto Preis</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        { task.files.map((file, i) => (
                                                                            <tr key={ i }>
                                                                                <td>
                                                                                    <div className="text-muted fw-bold text-ellipsis text-truncate" style={ { width: '200px' } }>
                                                                                        <a 
                                                                                            href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                            target="_blank"
                                                                                            className="text-muted text-ellipsis text-truncate d-inline-block w-100"
                                                                                        >
                                                                                            { file.filename }
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="text-muted text-ellipsis text-truncate" style={ { width: '185px' } }>
                                                                                        { file.invoice_file ? (
                                                                                            <div className="clearfix">
                                                                                                <div className="float-end">
                                                                                                    <button 
                                                                                                        type="button"
                                                                                                        className="btn btn-light btn-sm ms-2 px-0 py-0"
                                                                                                        onClick={ () => { 
                                                                                                            updateFile(i, {
                                                                                                                invoice_file: '',
                                                                                                                invoice_real_file: '',
                                                                                                            })
                                                                                                        } }
                                                                                                    >
                                                                                                        <i className="uil-times" />
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="clear text-truncate text-ellipsis">
                                                                                                    <a 
                                                                                                        href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.invoice_real_file}` } 
                                                                                                        target="_blank"
                                                                                                        className="text-dark"
                                                                                                    >
                                                                                                        { file.invoice_file }
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <>
                                                                                                { uploadInvoiceIndex === i ? (
                                                                                                    <div className="text-end mt-n2">
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-link text-muted btn-sm mb-n5"
                                                                                                            onClick={ () => {
                                                                                                                setUploadInvoiceIndex(undefined)
                                                                                                            } }
                                                                                                        >
                                                                                                            <i className="uil-times" />
                                                                                                        </button>
                                                                                                        <Dropzone
                                                                                                            getUploadParams={getInvoiceUploadParams}
                                                                                                            onChangeStatus={handleInvoiceChangeStatus}
                                                                                                            onSubmit={handleInvoiceSubmit}
                                                                                                            accept="*"
                                                                                                            maxFiles={1}
                                                                                                            multiple={false}
                                                                                                            SubmitButtonComponent={null}
                                                                                                            inputWithFilesContent="Datei hochladen"
                                                                                                            inputContent="Ziehen oder zum Durchsuchen klicken"
                                                                                                            classNames={ {
                                                                                                                inputLabel: 'dz-message needsclick font-18',
                                                                                                                dropzone: 'dropzone text-center needsclick'
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <button
                                                                                                        className="btn btn-light"
                                                                                                        onClick={ () => { 
                                                                                                            setUploadInvoiceIndex(i)
                                                                                                        } }
                                                                                                    >
                                                                                                        <i className="uil-plus-circle" /> Rechnung hochladen
                                                                                                    </button>
                                                                                                ) }
                                                                                            </>
                                                                                        ) }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-group">
                                                                                        <input 
                                                                                            type="number" 
                                                                                            className="form-control"
                                                                                            onChange={ (e) => {
                                                                                                updateAndRecalculateFilePrice(i, {
                                                                                                    unit_price: Number(e.target.value)
                                                                                                })
                                                                                            } }
                                                                                            value={ focusElement === `translation-file-unit-price-${i}` ? 
                                                                                                file.unit_price :
                                                                                                formatMoney(file.unit_price)
                                                                                            }
                                                                                            onFocus={() => {
                                                                                                setFocusElement(`translation-file-unit-price-${i}`)
                                                                                            }}
                                                                                            onBlur={() => {
                                                                                                setFocusElement(undefined)
                                                                                            }}
                                                                                        />
                                                                                        <span className="input-group-text">
                                                                                            <i className="uil-euro" />
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        value={ file.qty }
                                                                                        onChange={ (e) => {
                                                                                            updateAndRecalculateFilePrice(i, {
                                                                                                qty: Number(e.target.value)
                                                                                            })
                                                                                        } }
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-group">
                                                                                        <input 
                                                                                            type="number" 
                                                                                            className="form-control"
                                                                                            value={ formatMoney(file.price) }
                                                                                            disabled
                                                                                        />
                                                                                        <span className="input-group-text">
                                                                                            <i className="uil-euro" />
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-group">
                                                                                        <input 
                                                                                            type="number" 
                                                                                            className="form-control"
                                                                                            value={ file.tax_percentage }
                                                                                            onChange={ (e) => {
                                                                                                updateAndRecalculateFilePrice(i, {
                                                                                                    tax_percentage: Number(e.target.value)
                                                                                                })
                                                                                            } }
                                                                                        />
                                                                                        <span className="input-group-text">
                                                                                            %
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-group">
                                                                                        <input 
                                                                                            type="number" 
                                                                                            className="form-control"
                                                                                            value={ formatMoney(file.tax_amount) }
                                                                                            disabled
                                                                                        />
                                                                                        <span className="input-group-text">
                                                                                            <i className="uil-euro" />
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-group">
                                                                                        <input 
                                                                                            type="number" 
                                                                                            className="form-control"
                                                                                            value={ formatMoney(file.total_price) }
                                                                                            disabled
                                                                                        />
                                                                                        <span className="input-group-text">
                                                                                            <i className="uil-euro" />
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )) }
                                                                        <tr>
                                                                            <td colSpan={4}>
                                                                                <strong>Gesamt</strong>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        value={ formatMoney(task.price) }
                                                                                        disabled
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td></td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        value={ formatMoney(task.tax_amount) }
                                                                                        disabled
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        value={ formatMoney(task.total_price) }
                                                                                        disabled
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={4}>
                                                                                <strong>Zu zahlen</strong>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        onChange={ (e) => {
                                                                                            updateTask({
                                                                                                payment_price: Number(e.target.value),
                                                                                                payment_total_price: Number(e.target.value) + task.payment_tax_amount,
                                                                                                payment_is_modified: true,
                                                                                            })
                                                                                        }}
                                                                                        value={ focusElement === `translation-payment-price` ? 
                                                                                            task.payment_price :
                                                                                            formatMoney(task.payment_price)
                                                                                        }
                                                                                        onFocus={() => {
                                                                                            setFocusElement(`translation-payment-price`)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            setFocusElement(undefined)
                                                                                        }}
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td></td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        onChange={ (e) => {
                                                                                            updateTask({
                                                                                                payment_tax_amount: Number(e.target.value),
                                                                                                payment_total_price: Number(e.target.value) + task.payment_price,
                                                                                                payment_is_modified: true,
                                                                                            })
                                                                                        }}
                                                                                        value={ focusElement === `translation-payment-tax-amount` ? 
                                                                                            task.payment_tax_amount :
                                                                                            formatMoney(task.payment_tax_amount)
                                                                                        }
                                                                                        onFocus={() => {
                                                                                            setFocusElement(`translation-payment-tax-amount`)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            setFocusElement(undefined)
                                                                                        }}
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        value={ formatMoney(task.payment_total_price) }
                                                                                        disabled
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td colSpan={4}>
                                                                                <strong>Zahlung</strong>
                                                                            </td>
                                                                            <td colSpan={5}>
                                                                                <table className="table table-condensed table-bordered">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <p className="text-sm text-muted mb-0">
                                                                                                    Zahlungsmethode
                                                                                                </p>
                                                                                                <select
                                                                                                    className="form-select"
                                                                                                    value={ task.payment_method }
                                                                                                    onChange={ (e) => {
                                                                                                        updateTask({
                                                                                                            payment_method: e.target.value
                                                                                                        })
                                                                                                    } }
                                                                                                >
                                                                                                    <option value="">-Bitte auswählen-</option>
                                                                                                    <option value="PAYPAL">PayPal</option>
                                                                                                    <option value="STRIPE">Stripe</option>
                                                                                                    <option value="CASH">Bar</option>
                                                                                                    <option value="TRANSFER">Überwiesen</option>
                                                                                                </select>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="text-sm text-muted mb-0">
                                                                                                    Bezahlt?
                                                                                                </p>
                                                                                                <select
                                                                                                    className="form-select"
                                                                                                    value={ task.payment_is_paid ? 'PAID' : 'NOT_PAID' }
                                                                                                    onChange={ (e) => {
                                                                                                        updateTask({
                                                                                                            payment_is_paid: e.target.value === 'PAID',
                                                                                                            payment_paid_amount: e.target.value === 'PAID' ? task.payment_total_price : task.payment_paid_amount,
                                                                                                            payment_is_paid_status: e.target.value
                                                                                                        })
                                                                                                    } }
                                                                                                >
                                                                                                    <option value="PAID">Bezahlt</option>
                                                                                                    <option value="NOT_PAID">Nicht bezahlt</option>
                                                                                                    <option value="PARTIAL_PAID">Teilzahlung</option>
                                                                                                    <option value="CANCELLED">Storno</option>
                                                                                                </select>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="text-sm text-muted mb-0">
                                                                                                    Bezahlter Betrag
                                                                                                </p>
                                                                                                <div className="input-group">
                                                                                                    <input 
                                                                                                        type="number" 
                                                                                                        className="form-control"
                                                                                                        onChange={ (e) => {
                                                                                                            updateTask({
                                                                                                                payment_paid_amount: e.target.value ? Number(e.target.value) : '',
                                                                                                            })
                                                                                                        }}
                                                                                                        value={ focusElement === `translation-payment-paid-amount` ? 
                                                                                                            task.payment_paid_amount :
                                                                                                            formatMoney(task.payment_paid_amount)
                                                                                                        }
                                                                                                        onFocus={() => {
                                                                                                            setFocusElement(`translation-payment-paid-amount`)
                                                                                                        }}
                                                                                                        onBlur={() => {
                                                                                                            setFocusElement(undefined)
                                                                                                        }}
                                                                                                    />
                                                                                                    <span className="input-group-text">
                                                                                                        <i className="uil-euro" />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>

                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    
                                                                </table>
                                                            </div>
                                                        </>
                                                    ) }
                                                    { ["interpretation", "lesson"].includes(task.task_type) && (
                                                        <>
                                                            <h5 className="mb-3 mt-2">
                                                                Dolmetschen
                                                            </h5>
                                                            <div className="table-responsive">
                                                                <table className="table table-condensed table-bordered ">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Position</th>
                                                                            <th colSpan={2}>Menge</th>
                                                                            <th>Einzelpreis <span className="text-muted">(Netto)</span></th>
                                                                            <th>USt.</th>
                                                                            <th>Betrag <span className="text-muted">(Brutto)</span></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        { task.interpretation_billings.map((interpretation_billing, i) => (
                                                                            <React.Fragment key={ i }>
                                                                                <tr>
                                                                                    <td className="pb-1">
                                                                                        <input 
                                                                                            type="text" 
                                                                                            className="form-control"
                                                                                            value={ interpretation_billing.title }
                                                                                            onChange={ (e) => {
                                                                                                updateInterpretationBilling(i, {
                                                                                                    title: e.target.value
                                                                                                })
                                                                                            } }
                                                                                        />
                                                                                    </td>
                                                                                    <td className="pb-1">
                                                                                        <input 
                                                                                            type="number" 
                                                                                            className="form-control"
                                                                                            value={ interpretation_billing.amount }
                                                                                            onChange={ (e) => {
                                                                                                updateAndRecalculateInterpretationBilling(i, {
                                                                                                    amount: Number(e.target.value)
                                                                                                })
                                                                                            } }
                                                                                        />
                                                                                    </td>
                                                                                    <td className="pb-1">
                                                                                        <select 
                                                                                            className="form-select"
                                                                                            value={ interpretation_billing.amount_type }
                                                                                            onChange={ (e) => {
                                                                                                updateInterpretationBilling(i, {
                                                                                                    amount_type: e.target.value
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <option value="Stk">Stk</option>
                                                                                            <option value="m²">m²</option>
                                                                                            <option value="m">m</option>
                                                                                            <option value="kg">kg</option>
                                                                                            <option value="t">t</option>
                                                                                            <option value="lfm">lfm</option>
                                                                                            <option value="pauschal">pauschal</option>
                                                                                            <option value="m³">m³</option>
                                                                                            <option value="Std">Std</option>
                                                                                            <option value="km">km</option>
                                                                                            <option value="Tag(e)">Tag(e)</option>
                                                                                            <option value="L">L</option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td className="pb-1">
                                                                                        <div className="input-group">
                                                                                            <input 
                                                                                                type="number" 
                                                                                                className="form-control"
                                                                                                onChange={ (e) => {
                                                                                                    updateAndRecalculateInterpretationBilling(i, {
                                                                                                        unit_price: Number(e.target.value)
                                                                                                    })
                                                                                                } }
                                                                                                value={ focusElement === `interpretation-unit-price-${i}` ? 
                                                                                                    interpretation_billing.unit_price :
                                                                                                    formatMoney(interpretation_billing.unit_price)
                                                                                                }
                                                                                                onFocus={() => {
                                                                                                    setFocusElement(`interpretation-unit-price-${i}`)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    setFocusElement(undefined)
                                                                                                }}
                                                                                            />
                                                                                        
                                                                                            <span className="input-group-text px-1">
                                                                                                <i className="uil-euro" />
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="pb-1">
                                                                                        <div className="input-group">
                                                                                            <input 
                                                                                                type="number" 
                                                                                                className="form-control"
                                                                                                value={ interpretation_billing.tax_percentage }
                                                                                                onChange={ (e) => {
                                                                                                    updateAndRecalculateInterpretationBilling(i, {
                                                                                                        tax_percentage: Number(e.target.value)
                                                                                                    })
                                                                                                } }
                                                                                            />
                                                                                            <span className="input-group-text px-1">
                                                                                                %
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="pb-1">
                                                                                        <div className="input-group">
                                                                                            <input 
                                                                                                type="number" 
                                                                                                className="form-control"
                                                                                                value={ formatMoney(interpretation_billing.total_price) }
                                                                                                disabled
                                                                                            />
                                                                                            <span className="input-group-text px-1">
                                                                                                <i className="uil-euro" />
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="pb-1">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-light"
                                                                                            onClick={ () => {
                                                                                                removeInterpretationBilling(i)
                                                                                            } }
                                                                                        >
                                                                                            <i className="uil-times" />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={6} className="pt-1">
                                                                                        <textarea
                                                                                            type="text" 
                                                                                            className="form-control"
                                                                                            value={interpretation_billing.notes}
                                                                                            onChange={ (e) => {
                                                                                                updateInterpretationBilling(i, {
                                                                                                    notes: e.target.value
                                                                                                })
                                                                                            } }
                                                                                        />
                                                                                    </td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        )) }
                                                                        <tr>
                                                                            <td colSpan={5}>
                                                                                <strong>Gesamt</strong>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control px-1"
                                                                                        value={ formatMoney(task.total_price) }
                                                                                        disabled
                                                                                    />
                                                                                    <span className="input-group-text px-1">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-light"
                                                                                    onClick={ () => {
                                                                                        addInterpretationBilling()
                                                                                    } }
                                                                                >
                                                                                    <i className="uil-plus" />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={3}>
                                                                                <strong>Zu zahlen</strong>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        value={ formatMoney(task.payment_price) }
                                                                                        disabled
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        onChange={ (e) => {
                                                                                            updateTask({
                                                                                                payment_tax_amount: Number(e.target.value),
                                                                                                payment_price: task.payment_total_price - Number(e.target.value),
                                                                                                payment_is_modified: true,
                                                                                            })
                                                                                        }}
                                                                                        value={ focusElement === `interpretation-payment-tax-amount` ? 
                                                                                            task.payment_tax_amount :
                                                                                            formatMoney(task.payment_tax_amount)
                                                                                        }
                                                                                        onFocus={() => {
                                                                                            setFocusElement(`interpretation-payment-tax-amount`)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            setFocusElement(undefined)
                                                                                        }}
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        onChange={ (e) => {
                                                                                            updateTask({
                                                                                                payment_total_price: Number(e.target.value),
                                                                                                payment_price: Number(e.target.value) - task.payment_tax_amount,
                                                                                                payment_is_modified: true,
                                                                                            })
                                                                                        }}
                                                                                        value={ focusElement === `interpretation-payment-total-price` ? 
                                                                                            task.payment_total_price :
                                                                                            formatMoney(task.payment_total_price)
                                                                                        }
                                                                                        onFocus={() => {
                                                                                            setFocusElement(`interpretation-payment-total-price`)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            setFocusElement(undefined)
                                                                                        }}
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td colSpan={3}>
                                                                                <strong>Zahlung</strong>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-sm text-muted mb-0">
                                                                                    Zahlungsmethode
                                                                                </p>
                                                                                <select
                                                                                    className="form-select"
                                                                                    value={ task.payment_method }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            payment_method: e.target.value
                                                                                        })
                                                                                    } }
                                                                                >
                                                                                    <option value="">-Bitte auswählen-</option>
                                                                                    <option value="PAYPAL">PayPal</option>
                                                                                    <option value="STRIPE">Stripe</option>
                                                                                    <option value="CASH">Bar</option>
                                                                                    <option value="TRANSFER">Überwiesen</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-sm text-muted mb-0">
                                                                                    Bezahlt?
                                                                                </p>
                                                                                <select
                                                                                    className="form-select"
                                                                                    value={ task.payment_is_paid ? 'PAID' : 'NOT_PAID' }
                                                                                    onChange={ (e) => {
                                                                                        updateTask({
                                                                                            payment_is_paid: e.target.value === 'PAID',
                                                                                            payment_paid_amount: e.target.value === 'PAID' ? task.payment_total_price : task.payment_paid_amount,
                                                                                            payment_is_paid_status: e.target.value
                                                                                        })
                                                                                    } }
                                                                                >
                                                                                    <option value="PAID">Bezahlt</option>
                                                                                    <option value="NOT_PAID">Nicht bezahlt</option>
                                                                                    <option value="PARTIAL_PAID">Teilzahlung</option>
                                                                                    <option value="CANCELLED">Storno</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-sm text-muted mb-0">
                                                                                    Bezahlter Betrag
                                                                                </p>
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="number" 
                                                                                        className="form-control"
                                                                                        onChange={ (e) => {
                                                                                            updateTask({
                                                                                                payment_paid_amount: e.target.value ? Number(e.target.value) : '',
                                                                                            })
                                                                                        }}
                                                                                        value={ focusElement === `interpretation-payment-paid-amount` ? 
                                                                                            task.payment_paid_amount :
                                                                                            formatMoney(task.payment_paid_amount)
                                                                                        }
                                                                                        onFocus={() => {
                                                                                            setFocusElement(`interpretation-payment-paid-amount`)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            setFocusElement(undefined)
                                                                                        }}
                                                                                    />
                                                                                    <span className="input-group-text">
                                                                                        <i className="uil-euro" />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    
                                                                </table>
                                                            </div>
                                                            <div className="mb-3">
                                                                <h5>Rechnung</h5>
                                                                { task.interpretation_invoice ? (
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="card shadow-none border p-3">
                                                                                <div className="clearfix">
                                                                                    <div className="float-end">
                                                                                        <button 
                                                                                            type="button"
                                                                                            className="btn btn-light btn-sm ms-2 px-0 py-0"
                                                                                            onClick={ () => { 
                                                                                                updateTask({
                                                                                                    interpretation_invoice: undefined
                                                                                                })
                                                                                            } }
                                                                                        >
                                                                                            <i className="uil-times" />
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="clear text-truncate text-ellipsis">
                                                                                        <a 
                                                                                            href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${task.interpretation_invoice.invoice_real_file}` } 
                                                                                            target="_blank"
                                                                                            className="text-dark"
                                                                                        >
                                                                                            { task.interpretation_invoice.invoice_file }
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        { showInterpretationInvoiceUpload ? (
                                                                            <div className="text-end mt-n2">
                                                                                <button 
                                                                                    type="button"
                                                                                    className="btn btn-link text-muted btn-sm mb-n5"
                                                                                    onClick={ () => {
                                                                                        setShowInterpretationInvoiceUpload(undefined)
                                                                                    } }
                                                                                >
                                                                                    <i className="uil-times" />
                                                                                </button>
                                                                                <Dropzone
                                                                                    getUploadParams={getInvoiceUploadParams}
                                                                                    onChangeStatus={handleInterpretationInvoiceChangeStatus}
                                                                                    onSubmit={handleInterpretationInvoiceSubmit}
                                                                                    accept="*"
                                                                                    maxFiles={1}
                                                                                    multiple={false}
                                                                                    SubmitButtonComponent={null}
                                                                                    inputWithFilesContent="Datei hochladen"
                                                                                    inputContent="Ziehen oder zum Durchsuchen klicken"
                                                                                    classNames={ {
                                                                                        inputLabel: 'dz-message needsclick font-18',
                                                                                        dropzone: 'dropzone text-center needsclick'
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <button
                                                                                className="btn btn-light"
                                                                                onClick={ () => { 
                                                                                    setShowInterpretationInvoiceUpload(true)
                                                                                } }
                                                                            >
                                                                                <i className="uil-plus-circle" /> Rechnung hochladen
                                                                            </button>
                                                                        ) }
                                                                    </>
                                                                ) }
                                                            </div>
                                                        </>
                                                    ) }
                                                </div>
                                                <div
                                                    className={ `tab-pane d-print-block ${showTab === 'activity' && task.id && 'active'}` }
                                                >
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="alert alert-info">
                                                                <div className="row">
                                                                    { activityCurrentlyEditingIndex === undefined && !tmpActivityForm && (
                                                                        <div className="col-auto">
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-info'
                                                                                onClick={ () => {                                                                         
                                                                                    setTmpActivityForm(getNewActivity(task.id, {
                                                                                        id: currentUser.id,
                                                                                        role: 'admin',
                                                                                        name: `${currentUser.first_name} ${currentUser.last_name}`,
                                                                                    }, task.status))
                                                                                }}
                                                                            >
                                                                                Status ändern
                                                                            </button>
                                                                        </div>
                                                                    ) }
                                                                    <div className="col text-truncate text-ellipsis">
                                                                        Auftrag Status: <strong>{ statuses.find(s => s.key === task.status)?.value }</strong>{' '}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-alt pb-0">
                                                                { activityCurrentlyEditingIndex === undefined && tmpActivityForm ? (
                                                                    <div className="timeline-item">
                                                                        <i className="mdi mdi-circle bg-primary-lighten text-primary timeline-icon" />
                                                                        <div className="timeline-item-info">
                                                                            <div className="card bg-light shadow-none border">
                                                                                <div className="p-3 py-2">
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <h5 className="mt-3 mb-3">Verlauf Item hinzufügen</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="mt-0 mb-2 col-6">
                                                                                            <label
                                                                                                htmlFor="new_activity_type"
                                                                                                className="form-label"
                                                                                            >
                                                                                                Typ
                                                                                            </label>
                                                                                            <select
                                                                                                className="form-select"
                                                                                                id="new_activity_type"
                                                                                                value={ tmpActivityForm.is_status_change ? "1" : "0" }
                                                                                                onChange={ (e) => { 
                                                                                                    setTmpActivityForm((tmpActivityForm) => ({
                                                                                                        ...tmpActivityForm,
                                                                                                        is_status_change: e.target.value === "1" ? true : false
                                                                                                    }))
                                                                                                } }
                                                                                            >
                                                                                                <option value="1">Statusänderung</option>
                                                                                                <option value="0">Sonstiges</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        { !tmpActivityForm.is_status_change && (
                                                                                            <div className="mt-0 mb-2 col-6">
                                                                                                <label
                                                                                                    htmlFor="new_activity_subject"
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    Betreff
                                                                                                </label>
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    id="new_activity_subject"
                                                                                                    type="text"
                                                                                                    value={ tmpActivityForm.title }
                                                                                                    onChange={ (e) => { 
                                                                                                        setTmpActivityForm((tmpActivityForm) => ({
                                                                                                            ...tmpActivityForm,
                                                                                                            title: e.target.value
                                                                                                        }))
                                                                                                    } }
                                                                                                />
                                                                                            </div>
                                                                                        ) }
                                                                                    </div>
                                                                                    { tmpActivityForm.is_status_change && (
                                                                                        <div className="row">
                                                                                            <div className="mt-0 mb-2 col-6">
                                                                                                <label
                                                                                                    htmlFor="new_activity_previous_status"
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    Vorheriger Status
                                                                                                </label>
                                                                                                <select
                                                                                                    className="form-select"
                                                                                                    id="new_activity_previous_status"
                                                                                                    value={ tmpActivityForm.previous_status }
                                                                                                    disabled
                                                                                                >
                                                                                                    { statuses.map((status) => (
                                                                                                        <option 
                                                                                                            key={ status.key }
                                                                                                            value={ status.key }
                                                                                                        >{ status.value }</option>
                                                                                                    )) }
                                                                                                </select>
                                                                                            </div>
                                                                                            <div className="mt-0 mb-2 col-6">
                                                                                                <label
                                                                                                    htmlFor="new_activity_new_status"
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    Neuer Status
                                                                                                </label>
                                                                                                <select
                                                                                                    className="form-select"
                                                                                                    id="new_activity_new_status"
                                                                                                    value={ tmpActivityForm.new_status }
                                                                                                    onChange={ (e) => { 
                                                                                                        setTmpActivityForm((tmpActivityForm) => ({
                                                                                                            ...tmpActivityForm,
                                                                                                            new_status: e.target.value
                                                                                                        }))
                                                                                                    } }
                                                                                                >
                                                                                                    { statuses.map((status) => (
                                                                                                        <option 
                                                                                                            key={ status.key }
                                                                                                            value={ status.key }
                                                                                                        >{ status.value }</option>
                                                                                                    )) }
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) }
                                                                                    <div className="mt-0 mb-2">
                                                                                        <label
                                                                                            htmlFor="new_activity_description"
                                                                                            className="form-label"
                                                                                        >
                                                                                            Beschreibung (wird dem Kunden angezeigt)
                                                                                        </label>
                                                                                        <textarea 
                                                                                            className="form-control"
                                                                                            id="new_activity_description"
                                                                                            value={ tmpActivityForm.description }
                                                                                            onChange={ (e) => { 
                                                                                                setTmpActivityForm((tmpActivityForm) => ({
                                                                                                    ...tmpActivityForm,
                                                                                                    description: e.target.value
                                                                                                }))
                                                                                            } }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mt-0 mb-2">
                                                                                        <label
                                                                                            htmlFor="new_activity_note"
                                                                                            className="form-label"
                                                                                        >
                                                                                            Hinweis (wird dem Kunden nicht angezeigt)
                                                                                        </label>
                                                                                        <textarea 
                                                                                            className="form-control"
                                                                                            id="new_activity_note"
                                                                                            value={ tmpActivityForm.note }
                                                                                            onChange={ (e) => { 
                                                                                                setTmpActivityForm((tmpActivityForm) => ({
                                                                                                    ...tmpActivityForm,
                                                                                                    note: e.target.value
                                                                                                }))
                                                                                            } }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mb-3">
                                                                                        <div className="float-start">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id="new_activity_is_public"
                                                                                                data-switch="info"
                                                                                                checked={ tmpActivityForm.is_public }
                                                                                                onChange={ (e) => {
                                                                                                    setTmpActivityForm((tmpActivityForm) => ({
                                                                                                        ...tmpActivityForm,
                                                                                                        is_public: e.target.checked
                                                                                                    }))
                                                                                                } }
                                                                                            />
                                                                                            <label
                                                                                                htmlFor="new_activity_is_public"
                                                                                                data-on-label="Ja"
                                                                                                data-off-label="Nein"
                                                                                            />
                                                                                        </div>
                                                                                        <label
                                                                                            htmlFor="new_activity_is_public"
                                                                                            className="form-label ms-2"
                                                                                        >
                                                                                            Dem Kunden anzeigen 
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="mt-0 mb-2">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-info"
                                                                                            onClick={ () => {
                                                                                                persistActivity()
                                                                                            }}
                                                                                        >Speichern</button>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-light"
                                                                                            onClick={ () => {
                                                                                                setTmpActivityForm(undefined);
                                                                                            } }
                                                                                        >Abbrechen</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : activityCurrentlyEditingIndex === undefined && (
                                                                    <div className="timeline-item mb-3">
                                                                        <i className="mdi mdi-circle bg-primary-lighten text-primary timeline-icon" />
                                                                        <div className="timeline-item-info">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-info"
                                                                                onClick={ () => {                                                                        
                                                                                    setTmpActivityForm({
                                                                                        ...getNewActivity(task.id, {
                                                                                            id: currentUser.id,
                                                                                            role: 'admin',
                                                                                            name: `${currentUser.first_name} ${currentUser.last_name}`,
                                                                                        }, task.status),
                                                                                        is_status_change: false,
                                                                                    })
                                                                                }}
                                                                            >Verlauf Item hinzufügen</button>
                                                                        </div>
                                                                    </div>
                                                                ) }
                                                                { activity.map((activity, i) => (
                                                                    <div className="timeline-item">
                                                                        <i className={ `mdi mdi-circle ${i % 2 === 0 ? "bg-info-lighten text-info" : "bg-primary-lighten text-primary"} timeline-icon`} />
                                                                        <div className="timeline-item-info">
                                                                            { activityCurrentlyEditingIndex === i ? (
                                                                                <div className="card bg-light shadow-none border">
                                                                                    <div className="p-3 py-2">
                                                                                        <div className="row">
                                                                                            <div className="col-12">
                                                                                                <h5 className="mt-3 mb-3">Verlauf Item aktualisieren</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="mt-0 mb-2 col-6">
                                                                                                <label
                                                                                                    htmlFor={ `activity_${i}_type` }
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    Typ
                                                                                                </label>
                                                                                                <select
                                                                                                    className="form-select"
                                                                                                    id={ `activity_${i}_type` }
                                                                                                    value={ activity.is_status_change ? "1" : "0" }
                                                                                                    disabled
                                                                                                >
                                                                                                    <option value="1">Statusänderung</option>
                                                                                                    <option value="0">Sonstiges</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            { !activity.is_status_change && (
                                                                                                <div className="mt-0 mb-2 col-6">
                                                                                                    <label
                                                                                                        htmlFor={ `activity_${i}_subject` }
                                                                                                        className="form-label"
                                                                                                    >
                                                                                                        Betreff
                                                                                                    </label>
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        id={ `activity_${i}_subject` }
                                                                                                        type="text"
                                                                                                        value={ activity.title }
                                                                                                        onChange={ (e) => { 
                                                                                                            updateActivity(activity.id, {
                                                                                                                title: e.target.value
                                                                                                            })
                                                                                                        } }
                                                                                                    />
                                                                                                </div>
                                                                                            ) }
                                                                                        </div>
                                                                                        { activity.is_status_change && (
                                                                                            <div className="row">
                                                                                                <div className="mt-0 mb-2 col-6">
                                                                                                    <label
                                                                                                        htmlFor={ `activity_${i}_previous_status` }
                                                                                                        className="form-label"
                                                                                                    >
                                                                                                        Vorheriger Status
                                                                                                    </label>
                                                                                                    <select
                                                                                                        className="form-select"
                                                                                                        id={ `activity_${i}_previous_status` }
                                                                                                        value={ activity.previous_status }
                                                                                                        disabled
                                                                                                    >
                                                                                                        { statuses.map((status) => (
                                                                                                            <option 
                                                                                                                key={ status.key }
                                                                                                                value={ status.key }
                                                                                                            >{ status.value }</option>
                                                                                                        )) }
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="mt-0 mb-2 col-6">
                                                                                                    <label
                                                                                                        htmlFor={ `activity_${i}_new_status` }
                                                                                                        className="form-label"
                                                                                                    >
                                                                                                        Neuer Status
                                                                                                    </label>
                                                                                                    <select
                                                                                                        className="form-select"
                                                                                                        id={ `activity_${i}_new_status` }
                                                                                                        value={ activity.new_status }
                                                                                                        disabled
                                                                                                    >
                                                                                                        { statuses.map((status) => (
                                                                                                            <option 
                                                                                                                key={ status.key }
                                                                                                                value={ status.key }
                                                                                                            >{ status.value }</option>
                                                                                                        )) }
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) }
                                                                                        <div className="mt-0 mb-2">
                                                                                            <label
                                                                                                htmlFor={ `activity_${i}_description` }
                                                                                                className="form-label"
                                                                                            >
                                                                                                Beschreibung (wird dem Kunden angezeigt)
                                                                                            </label>
                                                                                            <textarea 
                                                                                                className="form-control"
                                                                                                id={ `activity_${i}_description` }
                                                                                                value={ activity.description }
                                                                                                onChange={ (e) => { 
                                                                                                    updateActivity(activity.id, {
                                                                                                        description: e.target.value
                                                                                                    })
                                                                                                } }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mt-0 mb-2">
                                                                                            <label
                                                                                                htmlFor={ `activity_${i}_note` }
                                                                                                className="form-label"
                                                                                            >
                                                                                                Hinweis (wird dem Kunden nicht angezeigt)
                                                                                            </label>
                                                                                            <textarea 
                                                                                                className="form-control"
                                                                                                id={ `activity_${i}_note` }
                                                                                                value={ activity.note }
                                                                                                onChange={ (e) => { 
                                                                                                    updateActivity(activity.id, {
                                                                                                        note: e.target.value
                                                                                                    })
                                                                                                } }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <div className="float-start">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={ `activity_${i}_is_public` }
                                                                                                    data-switch="info"
                                                                                                    checked={ activity.is_public }
                                                                                                    onChange={ (e) => { 
                                                                                                        updateActivity(activity.id, {
                                                                                                            is_public: e.target.checked
                                                                                                        })
                                                                                                    } }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={ `activity_${i}_is_public` }
                                                                                                    data-on-label="Ja"
                                                                                                    data-off-label="Nein"
                                                                                                />
                                                                                            </div>
                                                                                            <label
                                                                                                htmlFor={ `activity_${i}_is_public` }
                                                                                                className="form-label ms-2"
                                                                                            >
                                                                                                Dem Kunden anzeigen 
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="mt-0 mb-2">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-secondary"
                                                                                                onClick={ () => {
                                                                                                    setActivityCurrentlyEditingIndex(undefined);
                                                                                                } }
                                                                                            >Fertig</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className={ `card shadow-none border ${activity.is_public ? '' : 'bg-light'}` }>
                                                                                    <div className="p-3 py-2">
                                                                                        <h5 className="mt-2 mb-1">
                                                                                            <div className="float-end text-end">
                                                                                                { activityCurrentlyEditingIndex === undefined && (
                                                                                                    <>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-sm btn-light"
                                                                                                            onClick={ () => { 
                                                                                                                setActivityCurrentlyEditingIndex(i)
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="uil-edit" />
                                                                                                        </button>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className={ `btn btn-sm btn-light ${activity.is_status_change ? 'disabled opacity-25' : ''}` }
                                                                                                            disabled={ activity.is_status_change }
                                                                                                            onClick={ () => {
                                                                                                                if(!activity.is_status_change) {
                                                                                                                    removeActivity(activity.id)
                                                                                                                }
                                                                                                            } }
                                                                                                        >
                                                                                                            <i className="uil-times" />
                                                                                                        </button>
                                                                                                    </>
                                                                                                ) }
                                                                                                { !activity.is_public && (
                                                                                                    <div>
                                                                                                        <span className="badge bg-dark text-light">Privat</span>
                                                                                                    </div>
                                                                                                ) }
                                                                                            </div>
                                                                                            { activity.is_status_change ? (
                                                                                                <span>
                                                                                                    Der Status des Auftrags wurde von{' '}
                                                                                                    <span className="badge bg-secondary text-light">{ statuses.find(s => s.key === activity.previous_status)?.value }</span>
                                                                                                    {' '}zu{' '}
                                                                                                    <span className="badge bg-secondary text-light">{ statuses.find(s => s.key === activity.new_status)?.value }</span>
                                                                                                    {' '}geändert
                                                                                                </span>
                                                                                            ) : activity.title }
                                                                                        </h5>
                                                                                        <p className="font-14">
                                                                                            { activity.author.role === 'translator' ? (
                                                                                                <Link to={ `/admin/users/${activity.author.id}` } className="text-secondary" target="_blank">
                                                                                                    { activity.author.name } (Übersetzer)
                                                                                                </Link>
                                                                                            ) : activity.author.role === 'customer' ? (
                                                                                                <Link to={ `/admin/users/${activity.author.id}` } className="text-secondary" target="_blank">
                                                                                                    { activity.author.name } (Kunde)
                                                                                                </Link>
                                                                                            ) : <span>{ activity.author.name } (Administrator)</span> }
                                                                                            {" "}<span className="ms-2 font-12">{ apiToUiDate(activity.activity_date) }</span>
                                                                                        </p>
                                                                                        { activity.description && (
                                                                                            <>
                                                                                                <h5 className="mt-0 mb-2">Beschreibung</h5>
                                                                                                <div className="card bg-light-lighten shadow-none border">
                                                                                                    <div className="p-2">
                                                                                                        <p className="mt-0 mb-0">
                                                                                                            { activity.description }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        ) }
                                                                                        { activity.note && (
                                                                                            <>
                                                                                                <h5 className="mt-0 mb-2">Hinweis (wird dem Kunden nicht gezeigt)</h5>
                                                                                                <div className="card bg-warning-lighten shadow-none border">
                                                                                                    <div className="p-2">
                                                                                                        <p className="mt-0 mb-0">
                                                                                                            { activity.note }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        ) }
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )) }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={ `tab-pane d-print-block ${showTab === 'bids' && task.id && 'active'}` }
                                                >
                                                    <div className="row">
                                                        <div className="col-6 mb-3">
                                                            { task.bids.length > 0 ? (
                                                                <>
                                                                    Bewerbungen: {task.bids.length}<br />
                                                                    Höchste Bewerbung: {Math.max(...task.bids.map(b => b.price))} €<br /><br />
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            { task.bids.map((bid, i) => (
                                                                                <div key={i} className="mb-2 pb-2 border-bottom">
                                                                                    <strong>Datum:</strong><br />
                                                                                    { apiToUiDate(bid.bid_date) }<br /><br />
                                                                                    <strong>Bewerber: </strong><br />
                                                                                    <Link className="text-info" to={ `/customer/translators/${bid.translator.id}` } target="_blank">
                                                                                        {bid.translator.name} {bid.translator.email && `(${bid.translator.email})`}
                                                                                    </Link><br /><br />
                                                                                    <strong>Preis:</strong><br />
                                                                                    {bid.price} €<br /><br />
                                                                                    <strong>Bewerbungsschreiben</strong><br />
                                                                                    {bid.description}
                                                                                </div>
                                                                            )) }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>Es gibt noch keine Bewerbungen für diesen Auftrag</>
                                                            ) }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-6">
                                                    <button 
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >{ isNew ? 'Auftrag / Anfrage erstellen' : 'Auftrag / Anfrage aktualisieren' }</button>
                                                    <Link to="/admin/tasks">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light ms-2"
                                                        >Abbrechen</button>
                                                    </Link>
                                                </div>
                                                <div className="col-6 text-end">
                                                    { task.creation && (
                                                        <div>
                                                            Hinzugefügt von { task.creation.name } am { apiToUiDate(task.creation.activity_date) }
                                                        </div>
                                                    ) }
                                                    { task.update && (
                                                        <div>
                                                            Zuletzt aktualisiert von { task.update.name } am { apiToUiDate(task.update.activity_date) }
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>
                                        </form>
                                    </div>{" "}
                                    {/* end card-body */}
                                </div>{" "}
                                {/* end card*/}
                            </div>{" "}
                            {/* end col*/}
                        </div>
                        {/* end row*/}
                    </>
                ) }
            </div>
            <div className="d-print-block d-none">
                <TaskPrint 
                    task={ task }
                    customer={ customer }
                    languages={ languages }
                    statuses={ statuses }
                />
            </div>
        </>
    )
}

export default Task