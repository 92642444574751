// @ts-nocheck
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";

import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"

import {
    processAppSettingsKeyValueArray,
    processStaticSettingsObject,
} from 'utils/settings'

const fetchTasks = async (setTasks, { sortBy, limit, page, q, status, lang }, setIsLoading, authContext) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    setTasks(undefined)
    const tasks = await authGetRequest(`/my/translator/my-tasks?sortBy=${sortBy}&limit=${limit}&page=${page}&q=${q}&status=${status}&lang=${lang}`)
    setTasks(tasks.data)
    setIsLoading(false)
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const TranslatorMyTasksController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()

    const [tasks, setTasks] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    
    const languages = processAppSettingsKeyValueArray(appSettings.appSettings.languages)
    const taskTypes = processAppSettingsKeyValueArray(appSettings.appSettings.task_types)
    const statuses = processStaticSettingsObject(appSettings.staticSettings.statuses)

    const history = useHistory()
    const query = useQuery()

    const sortBy = query.get('sortBy') || 'entry_date'
    const limit = query.get('limit') || 10
    const page = query.get('page') || 1
    const q = query.get('q') || ''
    const status = query.get('status') || 'all';
    const lang = query.get('lang') || 'all';

    useEffect(() => {
        fetchTasks(setTasks, { sortBy, limit, page, q, status, lang }, setIsLoading, authContext)
    }, [sortBy, limit, page, q, status, lang])

    const updateSearchCriteria = (updates) => {
        if(updates.q !== undefined) {
            history.push(buildLink({ q: updates.q }))
        }
        if(updates.status !== undefined) {
            history.push(buildLink({ status: updates.status }))
        }
        if(updates.lang !== undefined) {
            history.push(buildLink({ lang: updates.lang }))
        }
    }

    const buildLink = ({ 
        page: newPage, 
        limit: newLimit, 
        sortBy: newSortBy,
        q: newQ,
        status: newStatus,
        lang: newLang,
    }) => {
        return `/translator/my-tasks?page=${newPage || page}&limit=${newLimit || limit}&sortBy=${newSortBy || sortBy}` + 
                `&q=${newQ !== undefined ? newQ : q}&status=${newStatus !== undefined ? newStatus : status}` +
                `&lang=${newLang !== undefined ? newLang : lang}`;
    }

    return {
        tasks,
        setTasks,
        isLoading,
        error,
        languages,
        statuses,
        taskTypes,
        buildLink,
        q,
        status,
        updateSearchCriteria,
        lang,
    }
}

export default TranslatorMyTasksController