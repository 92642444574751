// @ts-nocheck
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css';
import CustomerCalendarController from 'controllers/CustomerCalendar.controller'
import { apiToDisplayDate } from 'utils/datetime'

const CustomerCalendar = (props) => {
    const {
        fetchEvents,
        isLoading,
        languages
    } = CustomerCalendarController(props)

    const handleMouseEnter = (arg) =>{
        const task = arg.event.extendedProps.task
        const langs = arg.event.extendedProps.langs
        const translators = arg.event.extendedProps.translators

        let isHidden = false
        tippy(arg.el, {
            allowHTML: true,
            theme: 'light',
            onHide: (instance) => {
                if(!isHidden) {
                    isHidden = true
                    instance.destroy()
                }
            },
            onDestroy: () => {
                console.log("onDestroy")
            },
            content: (["interpretation", "lesson"].includes(task.task_type) ? '<strong>Dolmetschenauftrag</strong><br>' : '<strong>Übersetzungauftrag</strong><br>') +
                (task.customer?.name ? `<strong>Auftraggeber</strong>: ${task.customer.name}<br>` : '') +    
                (langs ? `<strong>Sprachen</strong>: ${langs}<br>` : '') +
                (translators ? `<strong>Auftragnehmer</strong>: ${translators}<br>` : '')
        })
    }

    console.log(languages, isLoading)

    return (
        <div className="mt-3">
            { !isLoading && (
                <FullCalendar
                    plugins={[ dayGridPlugin, bootstrapPlugin ]}
                    initialView="dayGridMonth"
                    themeSystem="bootstrap"
                    locale="de"
                    displayEventTime={false}
                    events={ fetchEvents }
                    eventMouseEnter={ handleMouseEnter }
                    bootstrapFontAwesome={ false }
                    buttonText={{
                        today: "Heute",
                        month: "Monat",
                        week: "Woche",
                        day: "Tag",
                        list: "Liste",
                        prev: "<",
                        next: ">",
                    }}
                />
            ) }
        </div>
    );
};

export default CustomerCalendar;
