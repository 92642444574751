// @ts-nocheck
import { Link, useHistory } from "react-router-dom"

import UsersController from 'controllers/admin/Users.controller'
import UsersSkeleton from 'screens/skeletons/admin/Users.skeleton'

import { apiToUiDate } from 'utils/datetime'

const User = (props) => {

    const {
        users,
        setUsers,
        isLoading,
        error,
        selectedDeleteDropdown,
        setSelectedDeleteDropdown,
        deleteUser,
        buildLink,
        languages,
        setShowColumnDropdown,
        showColumnDropdown,
        selectedColumns,
        updateSelectedColumns,
        availableColumns,
        roles,
        types,
        lang1,
        lang2,
        is_translator,
        is_interpreter,
        is_teacher,
        is_certified,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
        userType,
        currentUser,
        impersonateUser,
        setPopupDeleteUser,
        popupDeleteUser,
    } = UsersController(props)

    const history = useHistory()

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="float-end mt-3">
                            <Link to="/admin/users/new" className="btn btn-dark">
                                <i className="uil-plus"></i> Kontakt erstellen
                            </Link>
                        </div>
                        <h4 className="page-title"><strong className="text-black">Verwaltung</strong> / Kontakte</h4>
                    </div>
                </div>
            </div>
            {/* end page title */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-bordered mb-3">
                                <li className="nav-item">
                                    <a
                                        href="#"
                                        className={`nav-link rounded-0 ${userType === 'customer' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push(buildLink({ userType: 'customer', lang1: 'all', lang2: 'all' }))
                                        }}
                                    >
                                        Kunden
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="#"
                                        className={`nav-link rounded-0 ${userType === 'translator' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push(buildLink({ userType: 'translator' }))
                                        }}
                                    >
                                        Übersetzer:in / Dolmetscher:in
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="#"
                                        className={`nav-link rounded-0 ${userType === 'school' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push(buildLink({ userType: 'school' }))
                                        }}
                                    >
                                        Sprachschulen
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="#"
                                        className={`nav-link rounded-0 ${userType === 'admin' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push(buildLink({ userType: 'admin', lang1: 'all', lang2: 'all' }))
                                        }}
                                    >
                                        Administratoren
                                    </a>
                                </li>
                            </ul>
                            <div className="row mb-2">
                                <div className="col-xl-12">
                                    <form className="row gy-2 mb-2 gx-2 align-items-center justify-content-xl-start justify-content-between" onSubmit={handleUpdateSearch}>
                                        <div className="col-auto">
                                            <input
                                                type="search"
                                                className="form-control"
                                                placeholder="Suchen..."
                                                value={searchCriteria.q}
                                                onChange={(e) => {
                                                    setSearchCriteria((prev) => ({
                                                        ...prev,
                                                        q: e.target.value
                                                    }))
                                                }}
                                            />
                                        </div>
                                        {['translator', 'school'].includes(userType) && (
                                            <>
                                                <div className="col-auto">
                                                    <div className="d-flex align-items-center">
                                                        <label htmlFor="lang1" className="me-2">Sprache</label>
                                                        <select
                                                            className="form-select"
                                                            id="lang1"
                                                            value={lang1}
                                                            onChange={(e) => {
                                                                history.push(buildLink({ lang1: e.target.value }))
                                                            }}
                                                        >
                                                            <option value="all">Alle</option>
                                                            {languages.map(language => (
                                                                <option value={language.key} key={language.key}>{language.value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="d-flex align-items-center">
                                                        <label htmlFor="lang2" className="me-2">Sprache</label>
                                                        <select
                                                            className="form-select"
                                                            id="lang2"
                                                            value={lang2}
                                                            onChange={(e) => {
                                                                history.push(buildLink({ lang2: e.target.value }))
                                                            }}
                                                        >
                                                            <option value="all">Alle</option>
                                                            {languages.map(language => (
                                                                <option value={language.key} key={language.key}>{language.value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="d-flex align-items-center">
                                                        <label htmlFor="lang2" className="me-2">Sprache</label>
                                                        <select
                                                            className="form-select"
                                                            id="lang2"
                                                            value={lang2}
                                                            onChange={(e) => {
                                                                history.push(buildLink({ lang2: e.target.value }))
                                                            }}
                                                        >
                                                            <option value="all">Alle</option>
                                                            {languages.map(language => (
                                                                <option value={language.key} key={language.key}>{language.value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </form>

                                    {['translator', 'school'].includes(userType) && (
                                        <div className="row gy-2 gx-2 mb-3 align-items-center justify-content-xl-start justify-content-between">
                                            <div className="col-auto">
                                                <h5>Filtern nach: </h5>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="is-translator"
                                                        checked={is_translator}
                                                        onChange={(e) => {
                                                            history.push(buildLink({ is_translator: e.target.checked ? 1 : 0 }))
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="is-translator"
                                                    >
                                                        Übersetzer:in
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="is-certified"
                                                        checked={is_certified}
                                                        onChange={(e) => {
                                                            history.push(buildLink({ is_certified: e.target.checked ? 1 : 0 }))
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="is-certified"
                                                    >
                                                        Vereidigte:r Übersetzer:in
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="is-interpreter"
                                                        checked={is_interpreter}
                                                        onChange={(e) => {
                                                            history.push(buildLink({ is_interpreter: e.target.checked ? 1 : 0 }))
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="is-interpreter"
                                                    >
                                                        Dolmetscher:in
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="is-teacher"
                                                        checked={is_teacher}
                                                        onChange={(e) => {
                                                            history.push(buildLink({ is_teacher: e.target.checked ? 1 : 0 }))
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="is-teacher"
                                                    >
                                                        Lehrer:in
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-xl-12">
                                    <div className="float-end mt-n2">
                                        <button
                                            type="button"
                                            className="btn btn-light dropdown-toggle show-column-dropdown-trigger show"
                                            onClick={() => setShowColumnDropdown(true)}
                                        >
                                            Spalten anzeigen...
                                        </button>
                                        {showColumnDropdown && (
                                            <div
                                                className="dropdown-menu dropdown-menu-end show-column-dropdown show"
                                            >
                                                <div className="pt-2 ps-3">Spalten auswählen</div>
                                                <div className="dropdown-divider" />
                                                {Object.entries(availableColumns).map(([key, value]) => (
                                                    <div className="dropdown-item">
                                                        <div className="form-check form-checkbox-info mb-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`column_selector_${key}`}
                                                                checked={selectedColumns.includes(key)}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        updateSelectedColumns([
                                                                            ...selectedColumns,
                                                                            key
                                                                        ])
                                                                    }
                                                                    else {
                                                                        updateSelectedColumns(selectedColumns.filter(c => c !== key))
                                                                    }
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor={`column_selector_${key}`}>
                                                                {value}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {users && (
                                        <p className="pt-2">{users.totalResults} Ergebnisse</p>
                                    )}
                                </div>
                            </div>
                            {isLoading && <UsersSkeleton />}
                            {error && (
                                <div>{error}</div>
                            )}

                            {users && users.results.length === 0 && (
                                <div>Es wurden keine Kontakt gefunden</div>
                            )}

                            {users && users.results.length > 0 && (
                                <>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-centered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Aktionen</th>
                                                    {selectedColumns.includes('is_company') && (
                                                        <th>User Typ</th>
                                                    )}
                                                    {selectedColumns.includes('company_type') && (
                                                        <th>Organisation-Typ</th>
                                                    )}
                                                    {selectedColumns.includes('first_name') && (
                                                        <th>Vorname</th>
                                                    )}
                                                    {selectedColumns.includes('last_name') && (
                                                        <th>Nachname</th>
                                                    )}
                                                    {selectedColumns.includes('company_name') && (
                                                        <th>Name der Organisation</th>
                                                    )}
                                                    {selectedColumns.includes('email') && (
                                                        <th>E-Mail</th>
                                                    )}
                                                    {selectedColumns.includes('phones') && (
                                                        <th>Telefone</th>
                                                    )}
                                                    {selectedColumns.includes('addresses') && (
                                                        <th>Addressen</th>
                                                    )}
                                                    {selectedColumns.includes('customer_number') && (
                                                        <th>Kundennummer</th>
                                                    )}
                                                    {selectedColumns.includes('languages') && ['translator', 'school'].includes(userType) && (
                                                        <th>Sprachen</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.results.map((user) => {

                                                    const profile = user.profiles.find(p => {
                                                        if (['translator', 'school'].includes(userType) && p.role === 'translator') {
                                                            return true
                                                        }
                                                        if (userType === 'customer' && p.role === 'customer') {
                                                            return true
                                                        }
                                                        if (userType === 'admin' && p.role === 'admin') {
                                                            return true
                                                        }
                                                        return false
                                                    })
                                                    return (
                                                        <tr key={user.id} className={user.is_blocked ? 'bg-danger-lighten' : ''}>
                                                            <td>
                                                                <Link to={`/admin/users/${user.id}`} className="btn btn-default p-0">
                                                                    <i className="uil-edit"></i>
                                                                </Link>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-default p-0 px-2"
                                                                    onClick={() => setSelectedDeleteDropdown(user.id)}
                                                                >
                                                                    <i className="uil-trash"></i>
                                                                </button>
                                                                { user.has_account && !user.is_company && user.id !== currentUser.id && (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default p-0 px-2"
                                                                            onClick={() => impersonateUser(user)}
                                                                        >
                                                                            <i className="uil- uil-user-square"></i>
                                                                        </button>
                                                                        <Link to={`/admin/messenger/${user.id}`} className="btn btn-default p-0">
                                                                            <i className=" uil-comment-alt-message"></i>
                                                                        </Link>
                                                                    </>
                                                                ) }
                                                                {user.id === selectedDeleteDropdown && (
                                                                    <div className="dropdown-menu show">
                                                                        <h6 className="dropdown-header">Bestätigen</h6>
                                                                        <button
                                                                            type="button"
                                                                            className="dropdown-item"
                                                                            onClick={() => {
                                                                                setSelectedDeleteDropdown(undefined)
                                                                                setPopupDeleteUser(user.id)
                                                                            }}
                                                                        >
                                                                            <i className="uil-trash"></i>
                                                                            <span> Löschen</span>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="dropdown-item"
                                                                            onClick={() => setSelectedDeleteDropdown(undefined)}
                                                                        >
                                                                            <i className="uil-times"></i>
                                                                            <span> Abbrechen</span>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            {selectedColumns.includes('is_company') && (
                                                                <td>{user.is_company ? 'Organisation' : 'Person'}</td>
                                                            )}
                                                            {selectedColumns.includes('company_type') && (
                                                                <td>
                                                                    {user.company_type === 'company' && 'Firma'}
                                                                    {user.company_type === 'government' && 'Staatliche Institution'}
                                                                    {user.company_type === 'school' && 'Sprachschule'}
                                                                </td>
                                                            )}
                                                            {selectedColumns.includes('first_name') && (
                                                                <td>
                                                                    <Link to={`/admin/users/${user.id}`} className="btn btn-default p-0 text-start">
                                                                        {user.first_name}
                                                                    </Link>
                                                                </td>
                                                            )}
                                                            {selectedColumns.includes('last_name') && (
                                                                <td>
                                                                    <Link to={`/admin/users/${user.id}`} className="btn btn-default p-0 text-start">
                                                                        {user.last_name}
                                                                    </Link>
                                                                </td>
                                                            )}
                                                            {selectedColumns.includes('company_name') && (
                                                                <td>
                                                                    <Link to={`/admin/users/${user.id}`} className="btn btn-default p-0 text-start">
                                                                        {user.company_name}
                                                                    </Link>
                                                                </td>
                                                            )}
                                                            {selectedColumns.includes('email') && (
                                                                <td>
                                                                    {
                                                                        [
                                                                            (user.email && user.email),
                                                                            ...(profile?.personal_information?.emails ? profile.personal_information.emails : [])
                                                                        ].filter(e => e).join(', ')
                                                                    }
                                                                </td>
                                                            )}
                                                            {selectedColumns.includes('phones') && (
                                                                <td>
                                                                    {profile?.personal_information?.phones.map(p => (
                                                                        <span className="text-nowrap border px-1">{p}</span>
                                                                    ))}
                                                                </td>
                                                            )}
                                                            {selectedColumns.includes('addresses') && (
                                                                <td>
                                                                    {profile?.personal_information?.addresses.map(address => (
                                                                        <div className="text-nowrap border px-1">
                                                                            {address.address_street} {address.address_zip}<br />
                                                                            {address.address_city} {address.address_country}<br />
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                            )}
                                                            {selectedColumns.includes('customer_number') && (
                                                                <td>{profile?.customer_number}</td>
                                                            )}
                                                            {selectedColumns.includes('languages') && ['translator', 'school'].includes(userType) && (
                                                                <td>
                                                                    {profile?.translator_profile?.languages.map((l) => {
                                                                        const languageName = languages.find(lg => lg.key === l.language)?.value
                                                                        if (!languageName) {
                                                                            return undefined
                                                                        }

                                                                        return (l.is_native) ? `${languageName} (Muttersprache)` : languageName
                                                                    }).filter(l => !!l).join(' - ')}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="overflow-auto">
                                        <nav>
                                            <ul className="pagination mb-0 mt-3">
                                                {users.page > 1 && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={buildLink({ page: users.page - 1 })}
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">«</span>
                                                        </Link>
                                                    </li>
                                                )}
                                                {Array.from(Array(users.totalPages).keys()).map(i => i + 1).map((targetPage) => (
                                                    <li className={`page-item ${targetPage !== users.page ? "" : "active text-dark"}`}>
                                                        <Link to={buildLink({ page: targetPage })} className="page-link">
                                                            {targetPage}
                                                        </Link>
                                                    </li>
                                                ))}
                                                {users.page < users.totalPages && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={buildLink({ page: users.page + 1 })}
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">»</span>
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </nav>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            { popupDeleteUser && (
                <div className="popup-backdrop">
                    <div className="popup">
                        <p>
                            Möchten Sie wirklich diesen Kontakt löschen?
                        </p>
                        <div className="text-end">
                            <button
                                className="btn btn-primary me-1"
                                onClick={() => {
                                    deleteUser(popupDeleteUser)
                                    setPopupDeleteUser(undefined)
                                }}
                            >Ja</button>
                            <button
                                className="btn btn-light"
                                onClick={() => setPopupDeleteUser(undefined)}
                            >Nein</button>
                        </div>
                    </div>
                </div>
            ) }
        </div>
    )
}

export default User